import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { ChatMessageDto, GirlDetailsDto } from "@services/ayolApi/api.dtos";

import { getChatHistory, getGirlProfile } from "@services/ayolApi/methods";
import { ChatData, useChats } from "./Chats.context";

interface GirlProfileData {
  status: FetchStatus;
  girl: GirlDetailsDto | null;
  error: Error | AxiosError | null;
}

interface ContextValue {
  activeChat: ChatData | null;
  girlProfile: GirlProfileData;
  fetchInitMessagesStatus: FetchStatus;
  fetchPrevMessagesStatus: FetchStatus;
  getPreviousMessages: (before: string) => Promise<AxiosResponse<ChatMessageDto[]> | undefined>;
}

const FETCH_MESSAGES_LIMIT = 50;

const initialGirlProfileData = (): GirlProfileData => ({
  status: "loading",
  girl: null,
  error: null
});

const ActiveChatContext = React.createContext(null as any);

export const ActiveChatProvider = ({ children }: IContext) => {
  const [activeChat, setActiveChat] = useState<ChatData | null>(null);
  const [girlProfile, setGirlProfile] = useState<GirlProfileData>(initialGirlProfileData());

  const [fetchInitMessagesStatus, setFetchInitMessagesStatus] = useState<FetchStatus>("idle");
  const [fetchPrevMessagesStatus, setFetchPrevMessagesStatus] = useState<FetchStatus>("idle");

  const { chatId } = useParams();
  const { chats, status: fetchChatsStatus, getChat, updateChat, addPreviousMessages } = useChats();

  useEffect(() => {
    if (!chatId || fetchChatsStatus !== "success") return;
    const foundChat = getChat(chatId);

    if (foundChat) setActiveChat(foundChat);
  }, [chatId, chats, fetchChatsStatus]);

  useEffect(() => {
    if (!activeChat) return;

    fetchGirlProfile(activeChat.girl.id);
  }, [activeChat]);

  const fetchGirlProfile = async (girlId: string) => {
    try {
      initialGirlProfileData();

      const res = await getGirlProfile(girlId);

      if (res.status === 200) {
        setGirlProfile((prevState) => ({ ...prevState, girl: res.data, status: "success" }));
      }

      return res;
    } catch (e: any) {
      setGirlProfile((prevState) => ({ ...prevState, status: "failed", error: e }));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const initFetchMessages = async () => {
    if (
      !activeChat ||
      !chatId ||
      activeChat.id !== chatId ||
      activeChat.history.length > 1 ||
      fetchInitMessagesStatus === "loading"
    )
      return;

    // await sleep(300);
    const before = activeChat.history[0]?.createdAt || undefined;

    const res = await getChatHistory(activeChat.id, before, FETCH_MESSAGES_LIMIT);

    if (res.data) {
      setFetchInitMessagesStatus("success");

      if (res.data.length > 0) {
        addPreviousMessages(activeChat.id, res.data);
        updateChat({ id: activeChat.id, hasMoreMessages: true });
      } else {
        updateChat({ id: activeChat.id, hasMoreMessages: false });
      }
    } else {
      setFetchInitMessagesStatus("failed");
    }
  };

  const getPreviousMessages = async (beforeDate?: string) => {
    if (!activeChat?.hasMoreMessages || !activeChat || fetchPrevMessagesStatus === "loading") return;

    setFetchPrevMessagesStatus("loading");
    // await sleep(300);

    const before = beforeDate || activeChat?.history.length > 0 ? activeChat?.history[0].createdAt : undefined;

    const res = await getChatHistory(activeChat.id, before, FETCH_MESSAGES_LIMIT);

    if (res.data) {
      if (res.data.length > 0) {
        addPreviousMessages(activeChat.id, res.data);
        updateChat({ id: activeChat.id, hasMoreMessages: true });
      } else {
        updateChat({ id: activeChat.id, hasMoreMessages: false });
      }

      setFetchPrevMessagesStatus("success");
    } else {
      setFetchPrevMessagesStatus("failed");
    }

    return res;
  };

  useEffect(() => {
    if (activeChat?.id !== chatId) return;

    initFetchMessages();
  }, [activeChat, chatId]);

  const contextValue: ContextValue = {
    activeChat,
    girlProfile,
    fetchInitMessagesStatus,
    fetchPrevMessagesStatus,
    getPreviousMessages
  };

  return <ActiveChatContext.Provider value={contextValue}>{children}</ActiveChatContext.Provider>;
};

export const useActiveChat = (): ContextValue => {
  const context = React.useContext(ActiveChatContext);

  if (context === undefined) {
    throw new Error("useActiveChat must be used within an ActiveChatProvider");
  }

  return context;
};
