import React from "react";
import { useTranslation } from "react-i18next";

import { useActiveChat } from "@contexts/ActiveChat.context";
import Spinner from "@components/toolkit/Spinner/Spinner";

import "./FetchingPrevMessagesBadge.scss";

const FetchingPrevMessagesBadge = () => {
  const { fetchPrevMessagesStatus } = useActiveChat();

  const { t } = useTranslation(["common"]);

  const isFetching = fetchPrevMessagesStatus === "loading";

  if (isFetching) {
    return (
      <div className="fetching-prev-messages-badge">
        <Spinner variant="white" sizePx={20} />
        <p className="p1 loadingDots">{t("common:fetchingPreviousMessages")}</p>
      </div>
    );
  }

  return <></>;
};

export default FetchingPrevMessagesBadge;
