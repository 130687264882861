import { INSTAGRAM, TELEGRAM, TWITTER } from "./constants";

import { ReactComponent as TwitterIcon } from "@images/icons/socials/icon-twitter.svg";
import { ReactComponent as TelegramIcon } from "@images/icons/socials/icon-telegram.svg";
import { ReactComponent as InstagramIcon } from "@images/icons/socials/icon-instagram.svg";

export const socialLinks = [
  {
    title: "X",
    href: TWITTER,
    icon: <TwitterIcon />,
    sidebarIcon: <TwitterIcon width={32} height={32} color="#bab5be" />
  },
  {
    title: "Telegram",
    href: TELEGRAM,
    icon: <TelegramIcon />,
    sidebarIcon: <TelegramIcon width={32} height={32} color="#bab5be" />
  },
  {
    title: "Instagram",
    href: INSTAGRAM,
    icon: <InstagramIcon />,
    sidebarIcon: <InstagramIcon width={32} height={32} color="#bab5be" />
  }
];
