import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import TypingDots from "@components/common/TypingDots/TypingDots";

import "./MessageTyping.scss";

export type MessageType = "sending" | "receiving";

interface MessageTypingProps {
  sender?: string;
  type?: MessageType;
  className?: string;
}

const MessageTyping = ({ sender, type = "receiving", className }: MessageTypingProps) => {
  const { t } = useTranslation("common");
  const classes = classNames("message-typing", `message-typing--${type}`, className);

  return (
    <div className={classes}>
      <div className="message-typing-content">
        <TypingDots size={8} />
      </div>

      {sender && (
        <div className="message-typing-sender">
          <span>{t("senderIsTyping", { sender })}</span>
        </div>
      )}
    </div>
  );
};

export default MessageTyping;
