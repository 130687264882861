import { ayolApiClient } from "./ayolApiClient.instance";
import * as ApiDtos from "./api.dtos";

export const getCurrentUser = async () => {
  const path = "/auth/user";
  const method = "GET";

  return await ayolApiClient.sendAuthedRequest<any>({ url: path, method });
};

export const getUsersCount = async () => {
  const url = `/auth/users_count`;
  const method = "GET";

  return await ayolApiClient.sendRequest<ApiDtos.OnlineUsersCountDto>({ method, url });
};

export const getGirls = async () => {
  const url = `/girls`;
  const method = "GET";

  return await ayolApiClient.sendRequest<ApiDtos.GirlDto[]>({ method, url });
};

export const getTopTenGirls = async () => {
  const url = `/girls/top-ten`;
  const method = "GET";

  return await ayolApiClient.sendRequest<ApiDtos.GirlDto[]>({ method, url });
};

export const getGirlProfile = async (id: string) => {
  const url = `/girls/${id}`;
  const method = "GET";

  return await ayolApiClient.sendRequest<ApiDtos.GirlDetailsDto>({ method, url });
};

export const getCategorizedGirls = async () => {
  const url = `/categories`;
  const method = "GET";

  return await ayolApiClient.sendRequest<ApiDtos.CategorizedGirlsDto[]>({ method, url });
};

export const getAllChats = async (girlName?: string) => {
  const url = "/chats/all";
  const method = "GET";
  const params = {
    name: girlName
  };

  return await ayolApiClient.sendAuthedRequest<ApiDtos.ChatDto[]>({ method, url, params });
};

export const getChatHistory = async (chatId: string, before?: string, limit?: number) => {
  const url = `/chats/${chatId}/history`;
  const method = "GET";
  const params = {
    before, // timestamp
    limit
  };

  return await ayolApiClient.sendAuthedRequest<ApiDtos.ChatMessageDto[]>({ url, method, params });
};

export const createNewChat = async (girlId: string) => {
  const url = `/chats/new/${girlId}`;
  const method = "POST";

  return await ayolApiClient.sendAuthedRequest<ApiDtos.ChatDto>({ url, method });
};

export const sendChatMessage = async (chatId: string, message: string) => {
  const url = `/chats/${chatId}`;
  const method = "POST";
  const data = {
    message
  };

  return await ayolApiClient.sendAuthedRequest<ApiDtos.ChatDto>({ url, method, data });
};

export const fetchSetFavoriteChat = async (chatId: string, favorite: boolean) => {
  const url = `/chats/favorite/${chatId}`;
  const method = "PUT";
  const data = {
    favorite
  };

  return await ayolApiClient.sendAuthedRequest<ApiDtos.ChatDto>({ url, method, data });
};

export const fetchRemoveChat = async (chatId: string) => {
  const url = `/chats/${chatId}`;
  const method = "DELETE";

  return await ayolApiClient.sendAuthedRequest({ url, method });
};
