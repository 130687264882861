import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useActiveChat } from "@contexts/ActiveChat.context";

import { ChatStatus } from "@services/ayolApi/api.dtos";
import { sendChatMessage } from "@services/ayolApi/methods";

import { ReactComponent as MessageIcon } from "@images/icons/icon-message.svg";

import ChatTextarea from "@components/toolkit/inputs/ChatTextarea/ChatTextarea";
import SendButton from "@components/toolkit/buttons/SendButton/SendButton";

import "./SendMessageBox.scss";

const SendMessageBox = () => {
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { activeChat } = useActiveChat();
  const { t } = useTranslation("common");

  const handleChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    try {
      if (!activeChat || !message) return;
      setSubmitting(true);

      const res = await sendChatMessage(activeChat?.id, message);
      if (res.status === 201) {
        setMessage("");
      }

      return res;
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);

      //TODO: add error info
    } finally {
      setSubmitting(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const isTyping = useMemo(() => {
    return activeChat?.status === ChatStatus.GENERATING;
  }, [activeChat?.status]);

  return (
    <div className="send-message-box">
      <ChatTextarea name="chatMessage" value={message} onChange={handleChangeMessage} onKeyDown={handleKeyDown} />

      <SendButton onClick={handleSendMessage} disabled={submitting || isTyping}>
        <MessageIcon />
        {t("send")}
      </SendButton>
    </div>
  );
};

export default SendMessageBox;
