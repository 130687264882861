import React from "react";

import MainLayout from "@components/layouts/MainLayout/MainLayout";
import ChatHistoryList from "@app/Chats/components/ChatHistoryList/ChatHistoryList";
import ChatFeed from "./components/ChatFeed/ChatFeed";
import ChatGirlProfile from "./components/ChatGirlProfile/ChatGirlProfile";

import "./Chat.scss";

const Chat = () => {
  return (
    <MainLayout>
      <div className="chat-view">
        <div className="chat-view-chats-list">
          <ChatHistoryList />
        </div>

        <div className="chat-view-main">
          <ChatFeed />
        </div>

        <div className="chat-view-chat-girl-profile">
          <ChatGirlProfile />
        </div>
      </div>
    </MainLayout>
  );
};

export default (
  <>
    <Chat />
  </>
);
