import { useAppModals } from "@contexts/AppModals.context";

import RemoveChat from "./RemoveChat/RemoveChat";

const ChatsModals = () => {
  const { activeModal } = useAppModals();

  if (activeModal === null) return <></>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, data } = activeModal;

  if (type === "removeChat") return <RemoveChat chatId={data.chatId} />;

  return <></>;
};

export default ChatsModals;
