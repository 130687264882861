import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PATHS } from "@router/paths";

import Navbar from "@components/layoutElements/Navbar/Navbar";
import MobileToolbar from "@components/layoutElements/MobileToolbar/MobileToolbar";
import MainSidebar from "@components/layoutElements/MainSidebar/MainSidebar";
import Button from "@components/toolkit/buttons/Button/Button";

import { ReactComponent as ChevronLeft } from "@images/icons/icon-chevron-left.svg";

import "./MainLayout.scss";

interface LayoutProps {
  children: React.ReactNode;
  withGoBack?: boolean;
  className?: string;
}

const MainLayout = ({ children, withGoBack = false, className }: LayoutProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const classes = classNames("main-layout", className);

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(PATHS.main.path);
    }
  };

  return (
    <div className={classes}>
      <header className="header-part">
        <Navbar />
      </header>

      <div className="sidebar-part">
        <MainSidebar />
      </div>

      <main className="main-part">
        {withGoBack && (
          <div className="back-button">
            <Button variant="tertiary" onClick={goBack}>
              <ChevronLeft width={24} height={24} /> {t("back")}
            </Button>
          </div>
        )}
        {children}
      </main>

      <div className="mobile-toolbar-part">
        <MobileToolbar />
      </div>
    </div>
  );
};

export default MainLayout;
