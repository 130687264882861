import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import "./SidebarNavItem.scss";

interface SidebarNavItemProps {
  to: string;
  title?: string;
  active?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
  children: React.ReactNode | React.ReactElement;
}

const SidebarNavItem = ({ to, title = "", active, disabled, comingSoon, children }: SidebarNavItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const navigateTo = () => navigate(to);

  const classes = classNames("sidebar-nav-item", {
    "sidebar-nav-item--active": location.pathname === to || active,
    "sidebar-nav-item--soon": comingSoon,
    disabled
  });

  return (
    <button title={title} disabled={disabled} className={classes} onClick={navigateTo}>
      {children}
      {comingSoon && <span className="coming-soon">{t("soon")}</span>}
    </button>
  );
};

export default SidebarNavItem;
