import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { useWallet } from "@services/metamask/WalletProvider.context";
import { FetchStatus } from "@src/types/api/FetchStatus.types";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";
import Spinner from "@components/toolkit/Spinner/Spinner";

import "./SwitchNetwork.scss";

interface SwitchNetworkProps {
  onClose(): void;
}

const SwitchNetwork = ({ onClose }: SwitchNetworkProps) => {
  const [switchNetworkStatus, setSwitchNetworkStatus] = useState<FetchStatus>("idle");
  const [switchNetworkError, setSwitchNetworkError] = useState<string | null>(null);

  const { handleSwitchChain } = useWallet();
  const { t } = useTranslation(["modals", "apiResponse", "common"]);

  const correctNetwork = AppConfig.EthNetworkConfig;
  const networkName = correctNetwork.uiName;
  const networkImage = correctNetwork.logoSrc;

  const loading = switchNetworkStatus === "loading";

  const switchChain = async () => {
    if (loading) return;

    setSwitchNetworkError(null);
    setSwitchNetworkStatus("loading");

    await handleSwitchChain()
      .then(() => setSwitchNetworkStatus("success"))
      .catch((e) => {
        setSwitchNetworkStatus("failed");

        if (e?.code === 4001) {
          setSwitchNetworkError(t("apiResponse:error.userRejectedRequest"));
        } else {
          setSwitchNetworkError(t("apiResponse:error.userRejectedRequest"));
        }
      });
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <Stack alignItems="center" className="switch-network-modal">
            <img src={networkImage} alt={networkName} width={70} height="auto" className="mb-8" />
            <h3 className="mb-5">{t("modals:switchNetwork.title", { networkName })}</h3>
            <p className="p1 text-center mb-6">{t("modals:switchNetwork.description", { networkName })}</p>

            {switchNetworkError && <p className="p0 semi-bold danger">{switchNetworkError}</p>}

            <ModalButton onClick={switchChain}>
              {t("common:switchNetwork")}
              {loading && <Spinner variant="white" className="ml-3" />}
            </ModalButton>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SwitchNetwork;
