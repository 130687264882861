import { useAppModals } from "@contexts/AppModals.context";

import ConnectToMetamask from "./ConnectToMetamask/ConnectToMetamask";
import DesktopDownloadMetamask from "./DesktopDownloadMetamask/DesktopDownloadMetamask";
import FailedCreateChat from "./FailedCreateChat/FailedCreateChat";

const AppModals = () => {
  const { activeModal } = useAppModals();

  if (activeModal === null) return <></>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, data } = activeModal;

  if (type === "connectToMetamask") return <ConnectToMetamask />;
  if (type === "desktopDownloadMetamask") return <DesktopDownloadMetamask />;
  if (type === "failedCreateChat") return <FailedCreateChat />;

  return <></>;
};

export default AppModals;
