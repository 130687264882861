import React from "react";
import classNames from "classnames";

import { ReactComponent as StarIcon } from "@images/icons/icon-star.svg";
import { ReactComponent as StarFillIcon } from "@images/icons/icon-star-fill.svg";

import "./FavChatBadge.scss";

interface FavChatBadgeProps {
  favorite?: boolean;
  className?: string;
  onSetFavorite?(): void;
}

const FavChatBadge = ({ favorite = false, onSetFavorite, className }: FavChatBadgeProps) => {
  const handleSetFavorite = (e: any) => {
    e.stopPropagation();
    onSetFavorite?.();
  };

  const classes = classNames("fav-chat-badge", className);

  return (
    <button role="button" className={classes} onClick={handleSetFavorite}>
      {favorite ? <StarFillIcon width={24} height={24} /> : <StarIcon width={24} height={24} />}
    </button>
  );
};

export default FavChatBadge;
