import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useBlockScroll from "@hooks/useBlockScroll";
import { PATHS } from "@router/paths";
import { BUY_TOKEN, UNISWAP } from "@constants/constants";
import { scrollToTop } from "@utils/scrollUtils";

import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";
import Button from "@components/toolkit/buttons/Button/Button";
import OnlineUsersBadge from "@components/common/OnlineUsersBadge/OnlineUsersBadge";

import { ReactComponent as AffiliationIcon } from "@images/icons/icon-affiliation.svg";
import { ReactComponent as UniswapSignetIcon } from "@images/icons/icon-uniswap-signet.svg";
import { ReactComponent as ShoppingCartIcon } from "@images/icons/icon-shopping-cart.svg";
import { ReactComponent as HamburgerIcon } from "@images/icons/icon-hamburger.svg";

import MetamaskConnector from "../ConnectMetamaskBtn/MetamaskConnector";
import MobileMenu from "../MobileMenu/MobileMenu";
import "./Navbar.scss";

const Navbar = () => {
  const [menuOpen, setOpenMenu] = useState<boolean>(false);
  const { handleLocked, handleUnlocked } = useBlockScroll();
  const navigate = useNavigate();

  const { t } = useTranslation("layout");

  const handleOpenMenu = () => {
    setOpenMenu(true);
    handleLocked();
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    handleUnlocked();
  };

  const goHome = () => navigate(PATHS.main.path);

  const onLogoPress = () => {
    if (window.location.pathname !== PATHS.main.path) return goHome();
    return scrollToTop();
  };

  return (
    <>
      <div className="navbar">
        <button onClick={onLogoPress}>
          <img src="/images/branding/ayol-ai-full-logo.svg" alt="Ayol.ai" width={98} height={32} />
        </button>

        <Group className="navbar-actions">
          <OnlineUsersBadge className="xs-mr-4 lg-mr-6" />

          <Group className="navbar-actions-links">
            <Link variant="secondary" href={UNISWAP} className="uniswap-link pointer-events-none">
              <UniswapSignetIcon width={20} height={20} className="uniswap-link-icon" />
              {t("navbar.links.uniswap")}
            </Link>

            <Link variant="secondary" href={BUY_TOKEN} className="pointer-events-none">
              <ShoppingCartIcon /> {t("navbar.links.buyToken")}
            </Link>

            <Button className="px-6" comingSoon>
              <AffiliationIcon />
              {t("navbar.links.affiliation")}
            </Button>
          </Group>

          <Group colGap={8} className="navbar-actions-buttons">
            <MetamaskConnector onClick={handleCloseMenu} className="connect-metamask-btn" />

            <button onClick={handleOpenMenu} className="hamburger-btn">
              <HamburgerIcon />
            </button>
          </Group>
        </Group>
      </div>

      {menuOpen && <MobileMenu onClose={handleCloseMenu} />}
    </>
  );
};

export default Navbar;
