import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PATHS } from "@router/paths";
import { CharacterTypes } from "@services/ayolApi/api.dtos";
import { getGirlCharacter } from "@constants/girlCharacters";

import TopGirlCardSkeleton from "@components/skeletons/TopGirlCardSkeleton/TopGirlCardSkeleton";
import CharacterIcon from "../CharacterIcon/CharacterIcon";

import "./TopGirlCard.scss";

interface TopGirlCardProps {
  id: string;
  rank: number;
  name: string;
  category: string;
  imageUrl: string;
  character?: CharacterTypes;
  comingSoon?: boolean;
  className?: string;
}

const TopGirlCard = ({
  id,
  rank,
  name,
  category,
  imageUrl,
  character = "fiery",
  comingSoon,
  className
}: TopGirlCardProps) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setLoadingImage(false);
    };
  }, [imageUrl]);

  const navigateToGirlProfile = () => {
    if (isDragging || comingSoon) return;

    navigate(PATHS.girlProfile.getPath(id));
    setIsDragging(false);
  };

  const handleMouseDown = () => setIsDragging(false);
  const handleMouseMove = () => setIsDragging(true);

  const girlPersonality = getGirlCharacter(character);
  const rankNumber = rank.toString().padStart(2, "0");
  const classes = classNames("top-girl-card", className);

  if (loadingImage) {
    return <TopGirlCardSkeleton />;
  }

  return (
    <div
      className={classes}
      onClick={navigateToGirlProfile}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      <img src={imageUrl} alt={name} className="top-girl-image" />

      <div title={t(girlPersonality?.tKey as any)} className="top-girl-character-type">
        <CharacterIcon character={character} />
      </div>

      <div className="top-girl-details">
        <span className="top-girl-rank">{rankNumber}</span>

        <div className="top-girl-personalData">
          <span title={name} className="name">
            {name}
          </span>
          <span title={category} className="category">
            {category}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopGirlCard;
