import React from "react";
import { useTranslation } from "react-i18next";

import { AFFILIATE, WHITEPAPER } from "@constants/constants";

import { ReactComponent as ArrowUpRightIcon } from "@images/icons/icon-arrow-up-right.svg";
import { ReactComponent as ChevronRight } from "@images/icons/icon-chevron-right.svg";

import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation("layout");

  return (
    <footer className="footer">
      <div className="footer-row">
        <div className="row-column">
          <p className="p1 column-title">{t("footer.addToken.title")}</p>
          <img src="/images/blockchain/logo-metamask.svg" alt="Metamask" width={256} height={48} />
        </div>

        <div className="row-column" style={{ gap: "14px" }}>
          <p className="p1 column-title">{t("footer.tradeToken.title")}</p>
          <img src="/images/blockchain/logo-uniswap.svg" alt="Uniswap" width={230} height={62} />
        </div>

        <div className="row-column">
          <p className="p1 column-title">{t("footer.baseScan.title")}</p>
          <img src="/images/blockchain/logo-basescan.svg" alt="Base scan" height={46} />
        </div>
      </div>

      <div className="footer-row">
        <div className="row-column">
          <p className="p1 column-title">{t("footer.documentation.title")}</p>

          <Group colGap={16}>
            <img src="/images/icons/icon-document.svg" alt="Download whitepaper" width={48} height={48} />
            <Link href={WHITEPAPER} target="_blank">
              {t("footer.documentation.whitepaper")} <ChevronRight width={24} height={24} />
            </Link>
          </Group>

          {/* <Group colGap={16}>
            <img src="/images/icons/icon-video-player.svg" alt="Video explainer" width={48} height={48} />
            <Link href={VIDEO_EXPLAINER}>
              {t("footer.documentation.videoExplainer")} <ChevronRight />
            </Link>
          </Group> */}
        </div>

        <div className="row-column">
          <p className="p1 column-title">{t("footer.affiliate.title")}</p>
          <Link href={AFFILIATE} target="_blank">
            {t("footer.affiliate.contactUs")} <ArrowUpRightIcon width={17} />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
