import { get } from "env-var";
import { ethNetworkConfig } from "./ethNetworkConfig";

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const APP_VERSION = require("../../package.json").version;

export class AppConfig {
  // App ==================================================================================
  static readonly AppStage = get("REACT_APP_STAGE").asEnum(["prod", "dev"]);

  // API ==================================================================================
  static readonly AyolApiUrl = get("REACT_APP_GIRLS_API_URL").required().asUrlString();
  static readonly AyolSocketUrl = get("REACT_APP_GIRLS_API_SOCKET_URL").required().asUrlString();

  // Blockchain ===========================================================================
  private static EthTokenAddres = get("REACT_APP_ETH_TOKEN_ADDRESS").required().asString();
  private static EthTokenDecimals = get("REACT_APP_ETH_TOKEN_DECIMALS").default(8).asInt();
  private static EthTokenSymbol = get("REACT_APP_ETH_TOKEN_SYMBOL").default("SETH").asString();

  static readonly EthNetworkKey = get("REACT_APP_ETH_NETWORK_KEY").default("dev").asEnum(["mainnet", "sepolia", "dev"]);
  static readonly EhtAuthServiceName = get("REACT_APP_ETH_AUTH_SERVICE_NAME").default("test-aigirls").asString();

  static readonly EthNetworkConfig = ethNetworkConfig[this.EthNetworkKey];
  static readonly EthTokenConfig = {
    address: this.EthTokenAddres,
    decimals: this.EthTokenDecimals,
    symbol: this.EthTokenSymbol
  };

  // Other ================================================================================
  static readonly DisableEslintPlugin = get("DISABLE_ESLINT_PLUGIN").default("true").asBool();
  static readonly i18nDebugMode = get("REACT_APP_I18N_DEBUG_MODE").default("false").asBool();
  static readonly GlobalTimeout = 1000 * 8; //[ms]
}
