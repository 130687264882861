import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { useWallet } from "@services/metamask/WalletProvider.context";
import { useAppModals } from "@contexts/AppModals.context";
import { useAuth } from "@contexts/Auth.context";

import Button from "@components/toolkit/buttons/Button/Button";
import Spinner from "@components/toolkit/Spinner/Spinner";
import WalletAddress from "./WalletAddress/WalletAddress";

import "./MetamaskConnector.scss";

interface MetamaskConnectorProps {
  onClick?(): void;
  className?: string;
}

const MetamaskConnector = ({ onClick, className }: MetamaskConnectorProps) => {
  const { metamaskStatus, walletAddress } = useWallet();
  const { authStatus } = useAuth();
  const { openModal } = useAppModals();

  const { t } = useTranslation("common");

  const connecting = metamaskStatus === "connecting" || authStatus === "logging";
  const connected = metamaskStatus === "connected" && authStatus !== "logging";

  const handleConnect = () => {
    onClick?.();
    if (metamaskStatus === "unavailable") {
      openModal({ type: "desktopDownloadMetamask" });
    } else {
      openModal({ type: "connectToMetamask" });
    }
  };

  const classes = classNames("metamask-connector", className);

  return (
    <div className={classes}>
      {!connected && (
        <>
          <Button onClick={handleConnect} className="metamask-connector-button desktop-flex-md">
            <img
              src="/images/blockchain/metamask-signet.svg"
              alt="metamask signet"
              width={26}
              height={24}
              className="mr-2"
            />
            {connecting ? (
              <>
                {t("connecting")}
                <Spinner />
              </>
            ) : (
              <>{t("connectMetamask")}</>
            )}
          </Button>

          <Button onClick={onClick} className="metamask-connector-button mobile-flex-md">
            {connecting ? (
              <>
                {t("connecting")}
                <Spinner />
              </>
            ) : (
              <>
                <img
                  src="/images/blockchain/metamask-signet.svg"
                  alt="metamask signet"
                  width={26}
                  height={24}
                  className="mr-2"
                />
                {t("connect")}
              </>
            )}
          </Button>
        </>
      )}

      {connected && (
        <div className="metamask-connector-connected">
          <WalletAddress address={walletAddress || ""} />
        </div>
      )}
    </div>
  );
};

export default MetamaskConnector;
