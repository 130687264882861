import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";
import { useAppModals } from "@contexts/AppModals.context";
import { fetchRemoveChat } from "@services/ayolApi/methods";
import { useChats } from "@contexts/Chats.context";
import useRequest from "@hooks/useRequest";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";

import "./RemoveChat.scss";

interface RemoveChatModalProps {
  chatId: string;
}

const RemoveChat = ({ chatId }: RemoveChatModalProps) => {
  const navigate = useNavigate();
  const { closeModal } = useAppModals();
  const { removeChat } = useChats();
  const { t } = useTranslation("modals");

  const { request: requestRemoveChat, loading } = useRequest({
    requestFn: (chatId: string) => {
      return fetchRemoveChat(chatId);
    },
    onSuccess: () => {
      removeChat(chatId);
      closeModal();
      navigate(PATHS.chats.path);
    },
    onFail: (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  const handleRemoveChat = () => {
    if (loading) return;

    requestRemoveChat(chatId);
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <div className="remove-chat-modal">
            <p className="p3 mt-3">{t("removeChat.description")}</p>

            <ModalButton className="mt-3" onClick={handleRemoveChat} disabled={loading}>
              {t("removeChat.button")}
            </ModalButton>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default RemoveChat;
