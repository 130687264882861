import { DependencyList, useEffect } from "react";

import { AyolSocketEvents } from "./socket.types";
import { useGirlSocket } from "./AyolSocketContext";

const useSocketSubscribe = (
  eventName: AyolSocketEvents,
  eventHandler: (...args: any[]) => void,
  deps: DependencyList = []
) => {
  const { socket } = useGirlSocket();

  useEffect(() => {
    if (socket) {
      // console.log("SocketIO: adding listener", eventName);
      socket?.on(eventName, eventHandler);

      return () => {
        // console.log("SocketIO: removing listener", eventName);
        socket?.off(eventName, eventHandler);
      };
    }
  }, [eventName, socket, eventHandler, ...deps]);
};

export default useSocketSubscribe;
