import React, { useState } from "react";

import { AppConfig } from "@config/config";
import { MetamaskClient } from "../MetamaskClient";
import { bigNumberToPrecision } from "@utils/blockchain/bigNumbers";

type BalanceType = {
  bigint: bigint;
  number: number;
};

const MEANINGFULL_DECIMAL_PLACES = 4;

const useBalance = (walletAddress: string | null, chainId: string | null) => {
  const [accountBalance, setAccountBalance] = useState<BalanceType>({
    bigint: BigInt(0n),
    number: 0
  });

  const fetchBalance = async () => {
    if (!walletAddress) return;

    const balance = await MetamaskClient.getNativeBalance(walletAddress);

    const balanceAsNumber = bigNumberToPrecision(
      balance,
      AppConfig.EthTokenConfig.decimals,
      MEANINGFULL_DECIMAL_PLACES
    );

    setAccountBalance({
      bigint: balance,
      number: balanceAsNumber
    });
  };

  React.useEffect(() => {
    fetchBalance();
  }, [walletAddress, chainId]);

  return { accountBalance, fetchBalance };
};

export default useBalance;
