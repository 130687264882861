import { ChatDto, ChatMessageReceivedDto } from "@services/ayolApi/api.dtos";

export enum AyolSocketEvents {
  STATUS = "status",
  CHAT_STATUS = "chatStatus",
  CHAT_MESSAGE_RECEIVED = "chatMessageReceived"
}

export interface StatusData {
  msg: "unauthorized";
}

export interface ChatStatusData {
  chat: Pick<ChatDto, "id" | "status">;
}

export interface ChatMessageReceivedData {
  message: ChatMessageReceivedDto;
}
