import React from "react";
import classNames from "classnames";

import "./Spinner.scss";

interface SpinnerProps {
  variant?: "purple" | "white";
  sizePx?: number;
  className?: string;
}

const Spinner = ({ variant = "purple", sizePx = 22, className }: SpinnerProps) => {
  const classes = classNames("spinner", className);

  const spinnerSrc = `/images/layouts/gradient-spinner-${variant}.png`;

  return (
    <div className={classes}>
      <img src={spinnerSrc} alt="loading..." width={sizePx} height={sizePx} className="spinner-circle" />
    </div>
  );
};

export default Spinner;
