import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { PATHS } from "@router/paths";
import { CharacterTypes } from "@services/ayolApi/api.dtos";
import { getGirlCharacter } from "@constants/girlCharacters";

import GirlCardSkeleton from "@components/skeletons/GirlCardSkeleton/GirlCardSkeleton";
import CharacterIcon from "../CharacterIcon/CharacterIcon";

import "./GirlCard.scss";

interface GirlCardProps {
  id: string;
  name: string;
  age: number | null;
  imageUrl: string;
  character?: CharacterTypes;
  comingSoon?: boolean;
  className?: string;
}

const GirlCard = ({ id, name, age, imageUrl, character = "fiery", comingSoon, className }: GirlCardProps) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setLoadingImage(false);
    };
  }, [imageUrl]);

  const navigateToGirlProfile = () => {
    if (isDragging || comingSoon) return;

    navigate(PATHS.girlProfile.getPath(id));
    setIsDragging(false);
  };

  const handleMouseDown = () => setIsDragging(false);
  const handleMouseMove = () => setIsDragging(true);

  const girlCharacter = getGirlCharacter(character);
  const classes = classNames("girl-card", { "girl-card-coming-soon": comingSoon }, className);

  if (loadingImage) {
    return <GirlCardSkeleton />;
  }

  return (
    <div
      className={classes}
      onClick={navigateToGirlProfile}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      {comingSoon && (
        <>
          <span className="coming-soon-label top-left-coming-soon">{t("soon")}</span>
          <span className="coming-soon-label bottom-right-coming-soon">{t("soon")}</span>
        </>
      )}

      <img src={imageUrl} alt={name} className="girl-image" />

      <div title={t(girlCharacter?.tKey as any)} className="girl-type">
        <CharacterIcon character={character} />
      </div>

      <div className="card-content">
        <div className="girl-names">
          <span title={name} className="name">
            {name}
          </span>

          {age && (
            <span className="age">
              {t("age")}:{age}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GirlCard;
