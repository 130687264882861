import { Characters } from "../services/ayolApi/api.dtos";

export const girlCharacters = [
  {
    name: Characters.UNDERSTANDING,
    tKey: "common:characterType.understanding",
    iconUrl: "/images/character-types/understanding.svg"
  },
  {
    name: Characters.FIERY,
    tKey: "common:characterType.fiery",
    iconUrl: "/images/character-types/fiery.svg"
  },
  {
    name: Characters.FLIRTY,
    tKey: "common:characterType.flirty",
    iconUrl: "/images/character-types/flirty.svg"
  },
  {
    name: Characters.FRIENDLY,
    tKey: "common:characterType.friendly",
    iconUrl: "/images/character-types/friendly.svg"
  },
  {
    name: Characters.MYSTERIOUS,
    tKey: "common:characterType.mysterious",
    iconUrl: "/images/character-types/mysterious.svg"
  },
  {
    name: Characters.INTELLIGENT,
    tKey: "common:characterType.intelligent",
    iconUrl: "/images/character-types/intelligent.svg"
  },
  {
    name: Characters.SUBTLE,
    tKey: "common:characterType.subtle",
    iconUrl: "/images/character-types/subtle.svg"
  },
  {
    name: Characters.SENSUAL,
    tKey: "common:characterType.sensual",
    iconUrl: "/images/character-types/sensual.svg"
  },
  {
    name: Characters.UNPREDICTABLE,
    tKey: "common:characterType.unpredictable",
    iconUrl: "/images/character-types/unpredictable.svg"
  }
];

export const getGirlCharacter = (characterName: string) => {
  return girlCharacters.find((character) => character.name === characterName);
};
