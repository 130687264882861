import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useChats } from "@contexts/Chats.context";
import { debounce } from "@utils/debounce";

import ChatHistoryCard from "../ChatNavCard/ChatNavCard";
import ChatInput from "@components/toolkit/inputs/ChatInput/ChatInput";
import TypingDots from "@components/common/TypingDots/TypingDots";

import "./ChatHistoryList.scss";

const ChatHistoryList = () => {
  const [searchValue, setSearchValue] = useState("");
  const { chats, status, fetchAllChats } = useChats();

  const { t } = useTranslation(["chats", "common"]);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    if (searchValue.length > 0 && searchValue.length < 3) return;
    if (searchValue.length === 0) await fetchAllChats();

    await fetchAllChats(searchValue);
  };

  const debouncedChange = debounce(handleSearch as any, 300);

  const isLoading = status === "loading";
  const hasChats = !isLoading && chats.length > 0;
  const chatsEmpty = !isLoading && chats.length <= 0 && !searchValue.length;
  const chatsNotFound = !isLoading && chats.length <= 0 && searchValue.length >= 3;

  return (
    <div className="chat-history-list">
      <div className="chat-history-list-header">
        <p className="h4">{t("chats:chatHistoryList.title")}</p>
        <ChatInput type="search" placeholder="Search" onChange={debouncedChange} />
      </div>

      <div className="chat-history-list-main">
        {isLoading && (
          <div className="chat-history-list-loading">
            <TypingDots />
            <p className="p1 loading-text">{t("common:loading")}</p>
          </div>
        )}

        {hasChats &&
          chats?.map(({ id, girl, history, favorite }) => {
            return (
              <ChatHistoryCard
                key={id}
                id={id}
                name={girl.name}
                imageUrl={girl.profileImageUrl}
                lastMessage={history?.[history?.length - 1]?.content}
                favorite={favorite}
              />
            );
          })}

        {chatsEmpty && <p className="p1 w-100 text-center text-bold grey-5">{t("chats:chatHistoryList.chatsEmpty")}</p>}
        {chatsNotFound && (
          <p className="p1 w-100 text-center text-bold grey-5">{t("chats:chatHistoryList.chatsNotFound")}</p>
        )}
      </div>
    </div>
  );
};

export default ChatHistoryList;
