import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useOnlineUsersCount } from "@contexts/OnlineUsersCount.context";

import "./OnlineUsersBadge.scss";

interface OnlineUsersBadgeProps {
  className?: string;
}

const OnlineUsersBadge = ({ className }: OnlineUsersBadgeProps) => {
  const { userCount } = useOnlineUsersCount();
  const { t } = useTranslation("common");

  const classes = classNames("online-users-badge", className);

  return (
    <div className={classes}>
      <span className="online-users-badge-description">{t("connectedUsers")}</span>
      <img src="/images/icons/icon-online-users.svg" alt="Online users" width={32} height={32} />
      {/* <span className="online-users-badge-counter">{userCount || 0}</span> */}
      <span className="online-users-badge-counter">?</span>
    </div>
  );
};

export default OnlineUsersBadge;
