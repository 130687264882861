import React, { useState, useContext } from "react";
import classNames from "classnames";

import ClickAwayListener from "../ClickAwayListener/ClickAwayListener";
import useBoolean from "@hooks/useBoolean";

import DropdownTarget from "./common/DropdownTarget";
import DropdownMenu from "./common/DropdownMenu";
import DropdownMenuItem from "./common/DropdownMenuItem";

import { ContextValue, DropdownProps } from "./Dropdown.types";
import "./Dropdown.scss";

const DropdownDataContext = React.createContext(null as any);

const Dropdown = ({ children, placement = "bottom-end", className }: DropdownProps) => {
  const [dropdownRef, setDropdownRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMenuOpen, handleOpenMenu, handleCloseMenu, handleToggleOpenMenu] = useBoolean(false);

  const contextValue: ContextValue = {
    isMenuOpen,
    dropdownRef,
    popperRef,
    placement,
    setPopperRef,
    handleCloseMenu,
    handleToggleOpenMenu
  };

  const classes = classNames("dropdown", className);

  return (
    <DropdownDataContext.Provider value={contextValue}>
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div ref={setDropdownRef} className={classes}>
          {children}
        </div>
      </ClickAwayListener>
    </DropdownDataContext.Provider>
  );
};

export const useDropdownDataContext = (): ContextValue => useContext(DropdownDataContext);

Dropdown.Target = DropdownTarget;
Dropdown.Menu = DropdownMenu;
Dropdown.MenuItem = DropdownMenuItem;

export default Dropdown;
