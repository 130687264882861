import React from "react";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";
import { useActiveChat } from "@contexts/ActiveChat.context";

import Conversation from "../Conversation/Conversation";
import SendMessageBox from "../SendMessageBox/SendMessageBox";

import "./ChatFeed.scss";

const ChatFeed = () => {
  const navigate = useNavigate();
  const { activeChat } = useActiveChat();

  const navigateToGirlProfile = () => {
    if (!activeChat?.girl.id) return;
    navigate(PATHS.girlProfile.getPath(activeChat?.girl.id));
  };

  return (
    <div className="chat-feed">
      <div className="chat-feed-header">
        <button className="profile-button" onClick={navigateToGirlProfile}>
          <img
            src={activeChat?.girl.profileImageUrl}
            alt="Profile image"
            width={48}
            height={48}
            className="profile-image"
          />
          <p className="h4">{activeChat?.girl.name}</p>
        </button>
      </div>

      <div className="chat-feed-conversation">
        <Conversation />
      </div>

      <div className="chat-feed-send-message">
        <SendMessageBox />
      </div>
    </div>
  );
};

export default ChatFeed;
