import React from "react";
import classNames from "classnames";

import { formatDateTimeOrTime } from "@utils/formatDateTime";

import "./Message.scss";

export type MessageType = "sending" | "receiving";

interface MessageProps {
  children: React.ReactNode;
  timestamp?: number | string;
  type?: MessageType;
  className?: string;
}

const Message = ({ children, timestamp, type = "receiving", className }: MessageProps) => {
  const classes = classNames("message", `message--${type}`, className);

  return (
    <div className={classes}>
      <div className="message-content">{children}</div>

      {timestamp && (
        <div className="message-timestamp">
          <span>{formatDateTimeOrTime(timestamp)}</span>
        </div>
      )}
    </div>
  );
};

export default Message;
