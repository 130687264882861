import React, { useState } from "react";

import OnlyDevGuard from "@guards/OnlyDevGuard/OnlyDevGuard";

import "./Debug.scss";

const Debug = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState<{ x: number; y: number } | null>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setStartPos({ x: event.clientX, y: event.clientY });
    setIsDragging(false);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (startPos) {
      const diffX = Math.abs(startPos.x - event.clientX);
      const diffY = Math.abs(startPos.y - event.clientY);
      if (diffX > 10 || diffY > 10) {
        setIsDragging(true);
      }
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      console.log("Drag ended");
    } else {
      console.log("Clicked");
    }
    setStartPos(null);
  };

  return (
    <div className="debug-page">
      <h2>Debug page</h2>

      <br />
      <br />
      <div
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ width: 200, height: 200, backgroundColor: "lightgray" }}
      >
        Click or Drag inside this box
      </div>
    </div>
  );
};

export default (
  <OnlyDevGuard>
    <Debug />
  </OnlyDevGuard>
);
