import React from "react";
import { useTranslation } from "react-i18next";

import { useGirlsData } from "@contexts/GirlsData.context";
import { getTKeyGirlCategory } from "@constants/girlCategories";
import { CharacterTypes, Characters } from "@services/ayolApi/api.dtos";

import CardCarousel from "@components/common/CardCarousel/CardCarousel";
import TopGirlCard from "@components/common/TopGirlCard/TopGirlCard";

import "./TopTenGirls.scss";

const TopTenGirls = () => {
  const { topTenGirls } = useGirlsData();
  const { t } = useTranslation(["girlsPage", "common"]);

  return (
    <section id="main-page-top-ten-girls" className="top-ten-girls">
      <h1 className="mb-7 title">{t("girlsPage:topTenGirls.title")}</h1>

      <CardCarousel>
        {topTenGirls?.data?.map(({ id, name, categories, characters, profileImageUrl }, index) => {
          const category =
            categories.length && categories[0].category ? t(getTKeyGirlCategory(categories[0].category) as any) : "";
          const character = characters[0]?.key || Characters.FIERY;

          return (
            <TopGirlCard
              key={id}
              id={id}
              rank={index + 1}
              name={name}
              category={category}
              character={character as CharacterTypes}
              imageUrl={profileImageUrl}
            />
          );
        })}
      </CardCarousel>
    </section>
  );
};

export default TopTenGirls;
