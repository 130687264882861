import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import { useAuth } from "@contexts/Auth.context";
import { useWallet } from "@services/metamask/WalletProvider.context";

import AuthLoginModal from "./AuthLoginModal/AuthLoginModal";
import AuthReconnectModal from "./AuthReconnectModal/AuthReconnectModal";
import LoadingScreen from "@app/LoadingScreen/LoadingScreen";

const AuthGuard = ({ children }: IChildren) => {
  const { metamaskStatus } = useWallet();
  const { accessToken, tokenExpired, authStatus } = useAuth();

  const metamaskConnected = metamaskStatus === "connected";
  const chatInitialization = authStatus === "initial";
  const showLoginModal = (!accessToken && !tokenExpired && !chatInitialization) || !metamaskConnected;
  const showRecconectModal = !accessToken && tokenExpired && !chatInitialization;

  if (chatInitialization) return <LoadingScreen />;

  return (
    <>
      {children}
      {showLoginModal && <AuthLoginModal />}
      {showRecconectModal && <AuthReconnectModal />}
    </>
  );
};

export default AuthGuard;
