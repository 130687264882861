import React, { memo } from "react";
import classNames from "classnames";

import { SendButtonProps } from "./SendButton.types";
import "./SendButton.scss";

const SendButton = ({
  type = "button",
  title = "",
  disabled = false,
  loading = false,
  uppercase = true,
  fullWidth = false,
  testid = "",
  className = "",
  children,
  onClick = () => {},
  onBlur = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}: SendButtonProps) => {
  const classes = classNames(
    `send-button`,
    {
      "send-button-loading": loading,
      uppercase: uppercase,
      "full-width": fullWidth
    },
    className
  );

  return (
    <button
      type={type}
      title={title}
      data-testid={testid}
      disabled={disabled || loading}
      className={classes}
      onClick={onClick}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};

export default memo(SendButton);
