import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactZoomPanPinchContentRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import useKeyboard, { KeyCode } from "@hooks/use-keyboard";

import { ReactComponent as IconClose } from "@images/icons/icon-close.svg";
import { ReactComponent as ChevronLeft } from "@images/icons/icon-chevron-left.svg";
import { ReactComponent as ChevronRight } from "@images/icons/icon-chevron-right.svg";

import "./FullScreenSlider.scss";

const CHEVRON_SIZE = 48;

interface FullScreenSliderProps {
  initialIndex?: number;
  images: { id: string; imageUrl: string }[];
  onClose?(): void;
}

const FullScreenSlider = ({ initialIndex = 0, images, onClose }: FullScreenSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const transformWrapperRef = useRef<ReactZoomPanPinchContentRef>(null);
  const zoomIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const slideIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const { t } = useTranslation("common");

  const changeIndex = (direction: "next" | "previous") => {
    setCurrentIndex((prevIndex) => {
      const step = direction === "next" ? 1 : -1;
      const nextIndex = (prevIndex + step + images.length) % images.length;

      return nextIndex;
    });
  };

  const goToNext = () => changeIndex("next");
  const goToPrevious = () => changeIndex("previous");

  const startSliding = (slideDirection: "next" | "previous") => {
    stopSliding();

    const action = slideDirection === "next" ? goToNext : goToPrevious;

    action();
    slideIntervalRef.current = setInterval(action, 500);
  };

  const stopSliding = () => {
    if (slideIntervalRef.current) {
      clearInterval(slideIntervalRef.current);
      slideIntervalRef.current = null;
    }
  };

  const startZooming = (zoomDirection: "in" | "out") => {
    stopZooming();

    const action =
      zoomDirection === "in"
        ? () => transformWrapperRef.current?.zoomIn()
        : () => transformWrapperRef.current?.zoomOut();

    action();
    zoomIntervalRef.current = setInterval(action, 100);
  };

  const stopZooming = () => {
    if (zoomIntervalRef.current) {
      clearInterval(zoomIntervalRef.current);
      zoomIntervalRef.current = null;
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  useKeyboard(handleClose, [KeyCode.Escape]);

  useKeyboard(() => startSliding("previous"), [KeyCode.LeftArrow, KeyCode.KEY_A], { event: "keydown" });
  useKeyboard(() => startSliding("next"), [KeyCode.RightArrow, KeyCode.KEY_D], { event: "keydown" });
  useKeyboard(stopSliding, [KeyCode.LeftArrow, KeyCode.RightArrow, KeyCode.KEY_A, KeyCode.KEY_D], { event: "keyup" });

  useKeyboard(() => startZooming("in"), [KeyCode.UpArrow, KeyCode.KEY_W], { event: "keydown" });
  useKeyboard(() => startZooming("out"), [KeyCode.DownArrow, KeyCode.KEY_S], { event: "keydown" });
  useKeyboard(stopZooming, [KeyCode.UpArrow, KeyCode.DownArrow, KeyCode.KEY_W, KeyCode.KEY_S], { event: "keyup" });

  return (
    <div className="full-screen-slider">
      <div className="slider-overlay" onClick={onClose} />

      <div className="slider-content">
        <button className="slider-button left-button" onClick={goToPrevious}>
          <ChevronLeft width={CHEVRON_SIZE} height={CHEVRON_SIZE} />
        </button>

        <TransformWrapper
          ref={transformWrapperRef}
          key={currentIndex}
          initialScale={1}
          minScale={0.1}
          limitToBounds={false}
          centerOnInit
          wheel={{ step: 0.001 }}
        >
          <TransformComponent>
            <img src={images[currentIndex].imageUrl} alt={`Slide ${currentIndex}`} className="slider-image" />
          </TransformComponent>
        </TransformWrapper>

        <button className="slider-button right-button" onClick={goToNext}>
          <ChevronRight width={CHEVRON_SIZE} height={CHEVRON_SIZE} />
        </button>

        {onClose && (
          <button onClick={onClose} className="close-button">
            <IconClose /> {t("close")}
          </button>
        )}
      </div>
    </div>
  );
};

export default FullScreenSlider;
