import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import useNetworkStatus from "@hooks/useNetworkStatus";

import OfflineNetwork from "./OfflineNetwork/OfflineNetwork";

const OfflineNetworkGuard = ({ children }: IChildren) => {
  const isOnline = useNetworkStatus();

  return (
    <>
      {children}
      {!isOnline && <OfflineNetwork />}
    </>
  );
};

export default OfflineNetworkGuard;
