import { useCallback, useState } from "react";
import { AxiosResponse, AxiosError } from "axios";

import { FetchStatus } from "@src/types/api/FetchStatus.types";

interface UseRequestOptions<T, Y extends any[]> {
  requestFn: (...args: Y) => Promise<AxiosResponse<T, any>>;
  onSuccess?: (response: AxiosResponse<T>) => void;
  onFail?: (error: AxiosError | Error) => void;
  onFinally?(): void;
}

interface UseRequestReturn<T, Y extends any[]> {
  response: AxiosResponse<T> | null;
  error: AxiosError | null;
  loading: boolean;
  status: FetchStatus;
  request: (...args: Y) => Promise<AxiosResponse<T, any> | undefined>;
  reset: () => void;
}

function useRequest<T, Y extends any[]>({
  requestFn,
  onSuccess,
  onFail,
  onFinally
}: UseRequestOptions<T, Y>): UseRequestReturn<T, Y> {
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<FetchStatus>("idle");

  const request = useCallback(
    async (...args: Y) => {
      reset();
      setLoading(true);
      setStatus("loading");

      try {
        const res = await requestFn(...args);

        setResponse(res);
        setStatus("success");

        onSuccess?.(res);

        return res;
      } catch (e: unknown) {
        setStatus("failed");

        if (!(e instanceof AxiosError)) throw e;

        setError(e);

        onFail?.(e);
      } finally {
        setLoading(false);
        onFinally?.();
      }
    },
    [requestFn, onSuccess, onFail, onFinally]
  );

  const reset = useCallback(() => {
    setStatus("idle");
    setResponse(null);
    setError(null);
    setLoading(false);
  }, []);

  return { response, error, loading, status, request, reset };
}

export default useRequest;
