import React from "react";
import { useTranslation } from "react-i18next";

import { METAMASK_APP_APP_STORE, METAMASK_APP_GOOGLE_PLAY, OPEN_METAMASK_BROWSER_URL } from "@constants/constants";
import { DeviceInfo } from "@utils/browser/deviceInfo";

import { ReactComponent as ArrowUpRightIcon } from "@images/icons/icon-arrow-up-right.svg";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Link from "@components/navigation/Link/Link";

import "./MobileUnsupportedBrowser.scss";

interface ModalProps {
  onClose?(): void;
}

const MobileUnsupportedBrowser = ({ onClose }: ModalProps) => {
  const { t } = useTranslation(["modals"]);

  const isIOSDevice = DeviceInfo.isIosDevice;

  const downloadMetamaskImage = isIOSDevice
    ? "/images/layouts/app-store-sticker.svg"
    : "/images/layouts/google-play-sticker.svg";

  const downloadMetamaskLink = isIOSDevice ? METAMASK_APP_APP_STORE : METAMASK_APP_GOOGLE_PLAY;
  const openAppInMetamaskBrowserLink = OPEN_METAMASK_BROWSER_URL + window.location.href;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <div className="mobile-unsupported-browser">
            <img src="/images/blockchain/metamask-signet.svg" alt="Metamask" width={64} height={64} />
            <h4 className="text-center mt-5">{t("modals:mobileUnsupportedBrowser.title")}</h4>

            <p className="p1 mt-5">{t("modals:mobileUnsupportedBrowser.linkInfo")}</p>
            <Link href={openAppInMetamaskBrowserLink} className="open-metamask-link mt-3">
              <ArrowUpRightIcon width={17} />
              METAMASK.IO
            </Link>

            <p className="p1 mt-3 text-center w-100 md-w-70">
              {t("modals:mobileUnsupportedBrowser.downloadMetamaskInfo")}
            </p>

            <a href={downloadMetamaskLink} target="_blank" rel="noopener noreferrer nofollow">
              <img src={downloadMetamaskImage} alt="download metamask app" width={148.5} height={44} className="mt-6" />
            </a>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default MobileUnsupportedBrowser;
