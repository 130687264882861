import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { BaseProps } from "@src/types/BaseProps.types";

import "./ChatTextarea.scss";

interface ChatTextareaProps extends BaseProps<HTMLTextAreaElement> {
  value?: HTMLTextAreaElement["value"];
  placeholder?: string;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  className?: string;
  adornments?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ChatTextarea = ({
  value,
  placeholder = "enter value",
  disabled,
  minRows = 1,
  maxRows = 4,
  className,
  adornments,
  onChange,
  ...props
}: ChatTextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setTextareaValue] = useState(value);

  useEffect(() => {
    setTextareaValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value);
    onChange?.(event);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const style = window.getComputedStyle(textarea);
    const fontSize = parseFloat(style.fontSize);
    const lineHeight = parseFloat(style.lineHeight) || fontSize * 1.2;

    textarea.rows = minRows;
    const currentRows = Math.floor(textarea.scrollHeight / lineHeight);

    if (currentRows >= maxRows) {
      textarea.rows = maxRows;
      textarea.scrollTop = textarea.scrollHeight;
    } else {
      textarea.rows = Math.max(currentRows, minRows);
    }
  }, [textareaValue, minRows, maxRows]);

  const classes = classNames("chat-textarea", { disabled: disabled }, className);

  return (
    <div className={classes}>
      <textarea
        ref={textareaRef}
        rows={minRows}
        value={textareaValue}
        placeholder={placeholder}
        onChange={handleChange}
        className="chat-textarea-textarea"
        {...props}
      />

      {adornments && <div className="chat-textarea-adornments">{adornments}</div>}
    </div>
  );
};

export default ChatTextarea;
