import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { CharacterTypes } from "@services/ayolApi/api.dtos";
import { getGirlCharacter } from "@constants/girlCharacters";

import CharacterIcon from "../CharacterIcon/CharacterIcon";
import "./CharacterSticker.scss";

interface CharacterStickerProps {
  character?: CharacterTypes;
  rate?: number;
  className?: string;
}

const CharacterSticker = ({ character = "fiery", rate = 2, className }: CharacterStickerProps) => {
  const { t } = useTranslation("common");

  const girlCharacter = getGirlCharacter(character);
  const classes = classNames("character-sticker", className);

  return (
    <div className={classes}>
      <CharacterIcon character={character} />

      <div className="character-sticker-main">
        <p className="p0">{t(girlCharacter?.tKey as any)}</p>
        <p className="p2 purple-4 text-uppercase">
          {t("rate")}: {rate}/10
        </p>
      </div>
    </div>
  );
};

export default CharacterSticker;
