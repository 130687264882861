export interface SliderState {
  currentIndex: number;
  mouseStart: number;
  dragOffset: number;
  isDragging: boolean;
  fullScreenOpen: boolean;
}

export type SliderAction =
  | { type: "SET_CURRENT_INDEX"; index: number }
  | { type: "SET_DRAG_OFFSET"; offset: number }
  | { type: "START_DRAGGING"; start: number }
  | { type: "STOP_DRAGGING" }
  | { type: "SET_FULLSCREEN_OPEN"; isOpen: boolean };

export const initialState: SliderState = {
  currentIndex: 0,
  mouseStart: 0,
  dragOffset: 0,
  isDragging: false,
  fullScreenOpen: false
};

export const sliderReducer = (state: SliderState, action: SliderAction): SliderState => {
  switch (action.type) {
    case "SET_CURRENT_INDEX":
      return { ...state, currentIndex: action.index };
    case "SET_DRAG_OFFSET":
      return { ...state, dragOffset: action.offset };
    case "START_DRAGGING":
      return { ...state, isDragging: true, mouseStart: action.start };
    case "STOP_DRAGGING":
      return { ...state, isDragging: false, dragOffset: 0, mouseStart: 0 };
    case "SET_FULLSCREEN_OPEN":
      return { ...state, fullScreenOpen: action.isOpen };
    default:
      return state;
  }
};
