import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { ChatStatus } from "@services/ayolApi/api.dtos";
import { useActiveChat } from "@contexts/ActiveChat.context";
import { formatDate } from "@utils/formatDateTime";

import Stack from "@components/arrangement/Stack/Stack";
import Message, { MessageType } from "../Message/Message";
import MessageTyping from "../MessageTyping/MessageTyping";
import FetchingPrevMessagesBadge from "../FetchingPrevMessagesBadge/FetchingPrevMessagesBadge";

import "./Conversation.scss";

const CHECK_SCROLL_IS_TOP_OFFSET = 100;
const CHECK_SCROLL_IS_BOTTOM_OFFSET = 200;

const Conversation = () => {
  const messagesRef = useRef<HTMLDivElement>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const { chatId } = useParams();

  const { activeChat, fetchPrevMessagesStatus, getPreviousMessages } = useActiveChat();

  const handleGetPreviousMessages = async () => {
    if (!messagesRef.current || fetchPrevMessagesStatus === "loading" || !activeChat) return;

    const { scrollTop, scrollHeight } = messagesRef.current;
    const isCloseToTop = scrollTop < CHECK_SCROLL_IS_TOP_OFFSET;

    if (isCloseToTop && activeChat?.hasMoreMessages) {
      const scrollPositionBeforeLoad = scrollTop;
      const scrollHeightBeforeLoad = scrollHeight;

      await getPreviousMessages(activeChat?.history[0]?.createdAt || "");

      const scrollHeightAfterLoad = messagesRef.current.scrollHeight;
      const newScrollTop = scrollHeightAfterLoad - scrollHeightBeforeLoad + scrollPositionBeforeLoad;

      if (messagesRef.current) {
        messagesRef.current.scrollTop = newScrollTop;
      }
    }
  };

  const scrollToLatestMessage = () => {
    if (messagesRef.current) {
      const { scrollHeight, clientHeight } = messagesRef.current;

      if (clientHeight < scrollHeight && isScrolledToBottom) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }
  };

  const checkScrollIsBottom = () => {
    if (!messagesRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - CHECK_SCROLL_IS_BOTTOM_OFFSET;

    setIsScrolledToBottom(isAtBottom);
  };

  const isTyping = useMemo(() => {
    return activeChat?.status === ChatStatus.GENERATING;
  }, [activeChat?.status]);

  const isChatError = useMemo(() => {
    return activeChat?.status === ChatStatus.ERROR;
  }, [activeChat?.status]);

  useEffect(() => {
    const messagesRefCurrent = messagesRef.current;
    if (!messagesRefCurrent) return;

    messagesRefCurrent.addEventListener("scroll", handleGetPreviousMessages);
    messagesRefCurrent.addEventListener("scroll", checkScrollIsBottom);

    return () => {
      messagesRefCurrent.removeEventListener("scroll", handleGetPreviousMessages);
      messagesRefCurrent.removeEventListener("scroll", checkScrollIsBottom);
    };
  }, [activeChat?.id, handleGetPreviousMessages, checkScrollIsBottom]);

  useEffect(() => {
    scrollToLatestMessage();
  }, [activeChat?.history, isTyping, isChatError, chatId]);

  const messagesWithSeparators = useMemo(() => {
    let lastDate = "";

    if (activeChat?.id !== chatId) return <></>;

    return activeChat?.history.flatMap(({ id, createdAt, content, aiSide }) => {
      const currentDate = formatDate(createdAt);
      const items: JSX.Element[] = [];

      if (lastDate !== currentDate) {
        const formattedDate = formatDate(createdAt, undefined, "DD MMMM YYYY");
        items.push(
          <div className="date-separator" key={`separator-${id}`}>
            <p className="p1">{formattedDate}</p>
          </div>
        );
      }

      lastDate = currentDate;

      items.push(
        <Message key={id} type={aiSide ? "receiving" : ("sending" as MessageType)} timestamp={createdAt}>
          {content}
        </Message>
      );

      return items;
    });
  }, [activeChat?.history, chatId]);

  return (
    <div ref={messagesRef} className="conversation">
      <FetchingPrevMessagesBadge />

      {messagesWithSeparators}

      {isTyping && <MessageTyping sender={activeChat?.girl.name} />}

      {isChatError && (
        <Stack alignItems="center" fullWidth>
          <p className="p1 danger text-center" style={{ width: "100%" }}>
            Something went wrong! <br />
            Chat is not working properly, please refresh the page and try again.
          </p>
        </Stack>
      )}
    </div>
  );
};

export default Conversation;
