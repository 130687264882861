import React, { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { OnlineUsersCountDto } from "@services/ayolApi/api.dtos";
import { getUsersCount } from "@services/ayolApi/methods";

interface OnlineUsersCountData {
  status: FetchStatus;
  userCount: number | null;
  error: Error | AxiosError | null;
}

interface ContextValue extends OnlineUsersCountData {
  fetchOnlineUsersCount: () => Promise<AxiosResponse<OnlineUsersCountDto, any> | undefined>;
}

const initialContextData = (): OnlineUsersCountData => ({
  status: "loading",
  userCount: null,
  error: null
});

const OnlineUsersCountContext = React.createContext(null as any);

export const OnlineUsersCountProvider = ({ children }: IContext) => {
  const [onlineUsersCountData, setOnlineUsersCountData] = useState<OnlineUsersCountData>(initialContextData());

  useEffect(() => {
    fetchOnlineUsersCount();
  }, []);

  const fetchOnlineUsersCount = async () => {
    try {
      initialContextData();

      const res = await getUsersCount();

      if (res.status === 200) {
        setOnlineUsersCountData((prevState) => ({ ...prevState, userCount: res.data.count, status: "success" }));
      }

      return res;
    } catch (e: any) {
      setOnlineUsersCountData((prevState) => ({ ...prevState, userCount: null, status: "failed", error: e }));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const contextValue: ContextValue = {
    ...onlineUsersCountData,
    fetchOnlineUsersCount
  };

  return <OnlineUsersCountContext.Provider value={contextValue}>{children}</OnlineUsersCountContext.Provider>;
};

export const useOnlineUsersCount = (): ContextValue => {
  const context = React.useContext(OnlineUsersCountContext);

  if (context === undefined) {
    throw new Error("useOnlineUsersCount must be used within an OnlineUsersCountProvider");
  }

  return context;
};
