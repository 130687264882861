import React, { useEffect, useMemo } from "react";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { sleep } from "@utils/sleep";

import { CategorizedGirlsDto, GirlDto } from "@services/ayolApi/api.dtos";
import { getCategorizedGirls, getGirls, getTopTenGirls } from "@services/ayolApi/methods";

export interface ContextData<T> {
  status: FetchStatus;
  data: T | null;
  error: Error | AxiosError | null;
}

interface ContextValue {
  girlsData: ContextData<GirlDto[]>;
  topTenGirls: ContextData<GirlDto[]>;
  categorizedGirls: ContextData<CategorizedGirlsDto[]>;
  loadingGirlsData: boolean;
}

const initialContextData = <T,>(): ContextData<T> => ({
  status: "loading",
  data: null,
  error: null
});

const initialGirlsData = () => initialContextData<GirlDto[]>();
const initialCategorizedGirls = () => initialContextData<CategorizedGirlsDto[]>();

const GirlsDataContext = React.createContext(null as any);

export const GirlsDataProvider = ({ children }: IContext) => {
  const [girlsData, setGirlsData] = React.useState<ContextData<GirlDto[]>>(initialGirlsData());
  const [topTenGirls, setTopTenGirls] = React.useState<ContextData<GirlDto[]>>(initialGirlsData());
  const [categorizedGirls, setCategorizedGirls] = React.useState<ContextData<CategorizedGirlsDto[]>>(
    initialCategorizedGirls()
  );

  const loadingGirlsData = useMemo(() => {
    return girlsData.status === "loading" || topTenGirls.status === "loading" || categorizedGirls.status === "loading";
  }, [girlsData.status, topTenGirls.status, categorizedGirls.status]);

  const fetchAllGirls = async () => {
    try {
      initialGirlsData();

      await sleep(500);
      const res = await getGirls();

      if (res.status === 200) {
        setGirlsData((prevState) => ({ ...prevState, data: res.data, status: "success" }));
      }

      return res;
    } catch (e: any) {
      setGirlsData((prevState) => ({ ...prevState, status: "failed", error: e }));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const fetchTopTenGirls = async () => {
    try {
      initialGirlsData();

      await sleep(500);
      const res = await getTopTenGirls();

      if (res.status === 200) {
        setTopTenGirls((prevState) => ({ ...prevState, data: res.data, status: "success" }));
      }

      return res;
    } catch (e: any) {
      setTopTenGirls((prevState) => ({ ...prevState, status: "failed", error: e }));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const fetchCategorizedGirls = async () => {
    try {
      initialCategorizedGirls();

      await sleep(500);
      const res = await getCategorizedGirls();

      if (res.status === 200) {
        setCategorizedGirls((prevState) => ({ ...prevState, data: res.data, status: "success" }));
      }

      return res;
    } catch (e: any) {
      setCategorizedGirls((prevState) => ({ ...prevState, status: "failed", error: e }));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    fetchAllGirls();
    fetchTopTenGirls();
    fetchCategorizedGirls();
  }, []);

  const contextValue: ContextValue = {
    girlsData,
    topTenGirls,
    categorizedGirls,
    loadingGirlsData
  };

  return <GirlsDataContext.Provider value={contextValue}>{children}</GirlsDataContext.Provider>;
};

export const useGirlsData = (): ContextValue => {
  const context = React.useContext(GirlsDataContext);

  if (context === undefined) {
    throw new Error("useGirlsData must be used within an GirlsDataProvider");
  }

  return context;
};
