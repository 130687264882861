import React from "react";
import { useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";

import { ReactComponent as ChatIcon } from "@images/icons/icon-chat.svg";
import { ReactComponent as HeartIcon } from "@images/icons/icon-hamburger.svg";
import { ReactComponent as StarIcon } from "@images/icons/icon-star.svg";

import ToolbarNavItem from "./ToolbarNavItem/ToolbarNavItem";
import "./MobileToolbar.scss";

const MobileToolbar = () => {
  const { t } = useTranslation("layout");

  return (
    <div className="mobile-toolbar">
      <ToolbarNavItem to={PATHS.main.path}>
        <HeartIcon />
        {t("mainSidebar.actions.grils")}
      </ToolbarNavItem>

      <ToolbarNavItem to={PATHS.chats.path}>
        <ChatIcon />
        {t("mainSidebar.actions.chat")}
      </ToolbarNavItem>

      <ToolbarNavItem to="#" comingSoon>
        <StarIcon />
        {t("mainSidebar.actions.generate")}
      </ToolbarNavItem>
    </div>
  );
};

export default MobileToolbar;
