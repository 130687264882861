import React from "react";
import classNames from "classnames";

import { BaseProps } from "@src/types/BaseProps.types";

import "./ChatInput.scss";

interface ChatInputProps extends BaseProps<HTMLInputElement> {
  type?: HTMLInputElement["type"];
  value?: HTMLInputElement["value"];
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  adornments?: React.ReactNode;
}

const ChatInput = ({
  type = "text",
  value,
  placeholder = "enter value",
  disabled,
  className,
  adornments,
  ...props
}: ChatInputProps) => {
  const classes = classNames("chat-input", { disabled: disabled }, className);

  return (
    <div className={classes}>
      <input type={type} value={value} placeholder={placeholder} className="chat-input-input" {...props} />

      {adornments && <div className="chat-input-adornments">{adornments}</div>}
    </div>
  );
};

export default ChatInput;
