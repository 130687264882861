import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { APP_LANG } from "@config/helpers";

import "dayjs/locale/en";
import "dayjs/locale/pl";

dayjs.locale(APP_LANG);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);

export const FORMAT_DATE_TIME = "DD/MM/YYYY HH:mm";
export const FORMAT_DATE = "DD/MM/YYYY";
export const FORMAT_TIME = "HH:mm";

export const formatDateTime = (
  date: string | number | undefined,
  timezone: string | undefined = undefined,
  template?: string
) => {
  return dayjs(date)
    .tz(timezone)
    .format(template || FORMAT_DATE_TIME);
};

export const formatUtcDateTime = (date?: string | Dayjs | number, template?: string) => {
  return dayjs(date).utc().format(template);
};

export const formatDate = (date?: string | number, timezone?: string, template?: string) => {
  return dayjs(date)
    .tz(timezone)
    .format(template || FORMAT_DATE);
};

export const formatTime = (date?: string | number, timezone?: string, template?: string) => {
  return dayjs(date)
    .tz(timezone)
    .format(template || FORMAT_TIME);
};

export const getWeekdayName = (date: string | number, locales?: Intl.LocalesArgument) => {
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString(locales, { weekday: "long" });
};

export const formatDateWithWeekday = (
  date: string | number,
  locales: Intl.LocalesArgument = undefined,
  timezone: string | undefined = undefined
) => {
  const _date = formatDate(date, timezone);
  const _weekday = getWeekdayName(date, locales);
  const _time = formatTime(date, timezone);

  return `${_date} (${_weekday}) ${_time}`;
};

export const formatDateTimeOrTime = (date?: string | number, timezone?: string) => {
  const formattedDate = dayjs(date).tz(timezone);

  if (formattedDate.isToday()) {
    return formattedDate.format(FORMAT_TIME);
  } else {
    return formattedDate.format(FORMAT_DATE_TIME);
  }
};

export const formatDateTimeToUnix = (date?: string | number, timezone?: string) => dayjs(date).tz(timezone).unix();
