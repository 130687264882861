import { KeyMod } from "./codes";

export const isMac = (): boolean => {
  const isBrowser = Boolean(typeof window !== "undefined" && window.document && window.document.createElement);
  if (!isBrowser) return false;

  return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
};

export const getCtrlKeysByPlatform = (): Record<string, "metaKey" | "ctrlKey"> => {
  return {
    CtrlCmd: isMac() ? "metaKey" : "ctrlKey",
    WinCtrl: isMac() ? "ctrlKey" : "metaKey"
  };
};

export const getActiveModMap = (bindings: number[]): Record<keyof typeof KeyMod, boolean> => {
  const modBindings = bindings.filter((item: number) => !!KeyMod[item]);

  const activeModMap: Record<keyof typeof KeyMod, boolean> = {
    CtrlCmd: false,
    Shift: false,
    Alt: false,
    WinCtrl: false
  };

  modBindings.forEach((code) => {
    const modKey = KeyMod[code] as keyof typeof KeyMod;
    activeModMap[modKey] = true;
  });
  return activeModMap;
};
