import React, { CSSProperties, useRef } from "react";
import classNames from "classnames";

import useScrollButtons from "./hooks/useScrollButtons";
import useDraggableScroll from "./hooks/useDraggableScroll";

import { ReactComponent as CarouselChevronLeftIcon } from "@images/icons/icon-carousel-chevron-left.svg";
import { ReactComponent as CarouselChevronRightIcon } from "@images/icons/icon-carousel-chevron-right.svg";

import "./CardCarousel.scss";

interface CardCarouselProps {
  children: React.ReactNode | React.ReactElement;
  contentGap?: CSSProperties["gap"];
  className?: string;
  contentClassName?: string;
}

const CardCarousel = ({ children, contentGap = 10, className, contentClassName }: CardCarouselProps) => {
  const scrollContentRef = useRef<HTMLDivElement>(null);

  const { canScrollLeft, canScrollRight, scroll } = useScrollButtons(scrollContentRef);

  const { isDragging, handleMouseDown, handleMouseMove, handleMouseUp, handleMouseLeave } =
    useDraggableScroll(scrollContentRef);

  const handleScrollLeft = () => scroll("left");
  const handleScrollRight = () => scroll("right");

  const classes = classNames("card-carousel", className);
  const contentClasses = classNames("carousel-content", contentClassName);

  return (
    <div className={classes}>
      <div className="card-carousel-nav-buttons">
        <button className="card-carousel-button" onClick={handleScrollLeft} disabled={!canScrollLeft}>
          <CarouselChevronLeftIcon />
        </button>

        <button className="card-carousel-button" onClick={handleScrollRight} disabled={!canScrollRight}>
          <CarouselChevronRightIcon />
        </button>
      </div>

      <div
        ref={scrollContentRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        className={contentClasses}
        style={{
          gap: contentGap,
          cursor: isDragging ? "grabbing" : "pointer"
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CardCarousel;
