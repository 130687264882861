import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ethers } from "ethers";

import { AppConfig } from "@config/config";
import { CookieManager } from "@utils/browser/CookieManager";
import { ApiClientOptions, AxiosClient } from "@services/axios/AxiosClient";
import { AuthClient } from "@services/eth-auth";

import { AuthDto } from "./api.dtos";
import { ApiHeaders } from "./apiHeaders";
import { StorageKeys } from "./storageKeys";

export class AyolApiClient extends AxiosClient {
  constructor(options: ApiClientOptions) {
    super(options);
  }

  public async sendRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return await super.sendRequest<T>(config);
  }

  public async sendAuthedRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const accessToken = await this.getJWT();

      const headers = {
        ...config.headers,
        [ApiHeaders.AccessToken]: accessToken.token
      };

      const res = await super.sendRequest<T>({
        ...config,
        headers
      });

      return res;
    } catch (e: any) {
      throw e;
    }
  }

  public async getJWT(): Promise<AuthDto> {
    const accessTokenFromCookie = CookieManager.get(StorageKeys.AccessToken) || "";
    const tokenExpFromCookie = Number(CookieManager.get(StorageKeys.TokenExpTime)) || 0;

    if (!accessTokenFromCookie || !tokenExpFromCookie) {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum);
      const signer = await ethersProvider.getSigner();

      const authClient = new AuthClient(signer, {
        serviceName: AppConfig.EhtAuthServiceName,
        environment: "browser"
      });

      const msg = await authClient.createLoginMessage();

      const newAccessToken = await this.sendRequest<AuthDto>({
        method: "POST",
        url: "/auth/login",
        data: msg
      });

      const { exp, token } = newAccessToken.data;
      const expMiliseconds = exp * 1000;

      CookieManager.set(StorageKeys.AccessToken, token, { expires: expMiliseconds });
      CookieManager.set(StorageKeys.TokenExpTime, exp.toString(), { expires: expMiliseconds });

      return { token, exp };
    }

    return { token: accessTokenFromCookie, exp: tokenExpFromCookie };
  }
}
