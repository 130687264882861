import React from "react";

import "./TopGirlCardSkeleton.scss";

const TopGirlCardSkeleton = () => {
  return (
    <div className="top-girl-card-skeleton skeleton-animation">
      <div className="image-skeleton">
        <div className="skeleton-animation" />
      </div>
      <div className="details-skeleton">
        <div className="skeleton-animation" />
      </div>
    </div>
  );
};

export default TopGirlCardSkeleton;
