import React from "react";
import { useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";
import { CharacterTypes } from "@services/ayolApi/api.dtos";
import { getTKeyGirlCategory } from "@constants/girlCategories";
import { useActiveChat } from "@contexts/ActiveChat.context";

import ImageSlider from "@components/common/ImageSlider/ImageSlider";
import CharacterSticker from "@components/common/CharacterSticker/CharacterSticker";
import Button from "@components/toolkit/buttons/Button/Button";

import { ReactComponent as ImageIcon } from "@images/icons/icon-image.svg";
import { ReactComponent as HeartIcon } from "@images/icons/icon-heart.svg";

import "./ChatGirlProfile.scss";

const ChatGirlProfile = () => {
  const { girlProfile } = useActiveChat();
  const { girl, status } = girlProfile;
  const { t } = useTranslation(["common"]);

  const loading = status === "loading";

  const categoryName = t(getTKeyGirlCategory(girl?.categories?.[0].category || "") as any);
  const images = [...(girl?.images || []), ...(girl?.blurredPhotos || [])];

  const navigateToProfile = () => window.open(PATHS.girlProfile.getPath(girl?.id || ""), "_self");

  return (
    <div className="chat-girl-profile">
      <div className="chat-girl-profile-slider">{girl?.images && <ImageSlider images={images} />}</div>

      <div className="chat-girl-profile-info">
        <div className="profile-info-header">
          <p className="p2 category">{categoryName}</p>
          <h1 className="h2 name">{girl?.name}</h1>
        </div>

        <p className="p1 grey-2 mb-5 profile-info-description">{girl?.description}</p>

        <div className="profile-info-personality">
          {girl?.characters.map(({ key, value }) => {
            if (value <= 0) {
              return <></>;
            } else {
              return <CharacterSticker key={`${girl.id}-${key}`} character={key as CharacterTypes} rate={value} />;
            }
          })}
        </div>

        <hr className="breakLine no-select" />

        <div className="profile-info-buttons">
          <Button fullWidth onClick={navigateToProfile}>
            <HeartIcon /> {t("common:showProfile")}
          </Button>

          <Button variant="tertiary" fullWidth>
            <ImageIcon /> {t("common:generateImages")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatGirlProfile;
