import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthDto } from "@services/ayolApi/api.dtos";
import { IContext } from "@src/types/IContext.types";

import { useWallet } from "@services/metamask/WalletProvider.context";
import { ayolApiClient } from "@services/ayolApi/ayolApiClient.instance";
import { CookieManager } from "@utils/browser/CookieManager";
import { StorageKeys } from "@services/ayolApi/storageKeys";
import { getCurrentUser } from "@services/ayolApi/methods";
import { sleep } from "@utils/sleep";

export type AuthStatus = "initial" | "logging" | "loggedIn" | "loggedOut" | "tokenExpired" | "error";

export type AuthError = {
  type: "base" | "rejected";
  message: string;
};

interface ContextValue {
  accessToken: AuthDto | null;
  tokenExpired: boolean;
  authStatus: AuthStatus;
  authError: AuthError | null;
  login: () => Promise<void>;
  logout: () => void;
  changeAuthStatus: (status: AuthStatus) => void;
}

const AuthContext = React.createContext(null as any);

export const AuthProvider = ({ children }: IContext) => {
  const [accessToken, setAccessToken] = useState<AuthDto | null>(null);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [authStatus, setAuthStatus] = useState<AuthStatus>("initial");
  const [authError, setAuthError] = useState<AuthError | null>(null);

  const { isOnCorrectChain, connectMetamask, handleSwitchChain } = useWallet();
  const { t } = useTranslation(["apiResponse"]);

  const login = async () => {
    try {
      setAuthStatus("logging");
      setAuthError(null);

      const resConnectMetamask = await connectMetamask();

      if (!resConnectMetamask) {
        setAuthStatus("error");
        setAuthError({ type: "base", message: t("apiResponse:error.base") });
      }

      if (!isOnCorrectChain) {
        await handleSwitchChain();
      }

      const authToken = await ayolApiClient.getJWT();

      if (authToken) {
        setAccessToken(authToken);
        setTokenExpired(false);
        setAuthStatus("loggedIn");
      } else {
        setAuthStatus("loggedOut");
      }
    } catch (e: any) {
      setAuthStatus("error");

      if (e?.code === 4001) {
        setAuthError({ type: "rejected", message: t("apiResponse:error.userRejectedRequest") });
      } else {
        setAuthError({ type: "base", message: t("apiResponse:error.userRejectedRequest") });
      }
    }
  };

  const authorizeWithSavedData = useCallback(async () => {
    const token = CookieManager.get(StorageKeys.AccessToken);
    const exp = Number(CookieManager.get(StorageKeys.TokenExpTime));

    await sleep(300);

    if (token && exp) {
      setAuthStatus("initial");
      // check token is not expired
      // if the token is valid for more than a minute, we consider it expired
      const isExpired = exp * 1000 - new Date().getTime() <= 1000 * 60;

      if (isExpired) {
        setAuthStatus("loggedOut");
        CookieManager.remove(StorageKeys.AccessToken);
        CookieManager.remove(StorageKeys.TokenExpTime);

        return;
      }

      const res = await getCurrentUser();

      if (res.status === 200) {
        setAccessToken({ token, exp });
        setTokenExpired(false);
        setAuthStatus("loggedIn");
      } else {
        setAuthStatus("loggedOut");

        CookieManager.remove(StorageKeys.AccessToken);
        CookieManager.remove(StorageKeys.TokenExpTime);
      }
    } else {
      setAuthStatus("loggedOut");
    }
  }, []);

  const logout = () => {
    setAccessToken(null);
    setTokenExpired(false);
    setAuthStatus("loggedOut");

    CookieManager.remove(StorageKeys.AccessToken);
    CookieManager.remove(StorageKeys.TokenExpTime);
  };

  const removeExpiredToken = () => {
    setAccessToken(null);
    setTokenExpired(true);
    setAuthStatus("tokenExpired");

    CookieManager.remove(StorageKeys.AccessToken);
    CookieManager.remove(StorageKeys.TokenExpTime);
  };

  const changeAuthStatus = (status: AuthStatus) => setAuthStatus(status);

  useEffect(() => {
    authorizeWithSavedData();
  }, []);

  // Remove saved access token when expired
  useEffect(() => {
    if (accessToken) {
      // accessToken.exp convert s to ms
      const delay = accessToken.exp * 1000 - new Date().getTime() - 1000;

      if (delay > 0) {
        const expTokenTimer = setTimeout(() => {
          removeExpiredToken();
        }, delay);

        return () => clearTimeout(expTokenTimer);
      } else {
        removeExpiredToken();
      }
    }
  }, [accessToken]);

  const contextValue: ContextValue = {
    accessToken,
    tokenExpired,
    authStatus,
    authError,
    login,
    logout,
    changeAuthStatus
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): ContextValue => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
