import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PATHS } from "@router/paths";
import { createNewChat } from "@services/ayolApi/methods";
import { useChats } from "@contexts/Chats.context";
import { useAppModals } from "@contexts/AppModals.context";

import MainLayout from "@components/layouts/MainLayout/MainLayout";
import LoadingScreen from "@app/LoadingScreen/LoadingScreen";
import ChatHistoryList from "./components/ChatHistoryList/ChatHistoryList";

import "./Chats.scss";

const GIRL_ID_PARAM = "girl";

const Chats = () => {
  const [searchParams] = useSearchParams();
  const girlId = searchParams.get(GIRL_ID_PARAM);

  const { openModal } = useAppModals();
  const navigate = useNavigate();

  const { status: chatsStatus, findChatByGirlId, parseRawChat, addNewChat } = useChats();

  useEffect(() => {
    const handleNavigateToChat = async () => {
      if (!girlId || chatsStatus !== "success") return;

      const chatExists = findChatByGirlId(girlId);

      if (chatExists?.id) {
        navigate(PATHS.chat.getPath(chatExists?.id), { replace: true });
      } else {
        try {
          const res = await createNewChat(girlId);

          if (res.status === 201) {
            const newChat = parseRawChat(res.data);

            addNewChat(newChat);
            navigate(PATHS.chat.getPath(res.data.id), { replace: true });
          }
        } catch (e) {
          navigate(PATHS.girlProfile.getPath(girlId));
          openModal({ type: "failedCreateChat" });

          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    };

    handleNavigateToChat();
  }, [girlId, chatsStatus]);

  if (girlId) return <LoadingScreen />;

  return (
    <MainLayout>
      <div className="chats-view">
        <div className="chats-view-chats-list">
          <ChatHistoryList />
        </div>
      </div>
    </MainLayout>
  );
};

export default (
  <>
    <Chats />
  </>
);
