import React, { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { ChatDto, ChatMessageDto } from "@services/ayolApi/api.dtos";

import useSocketSubscribe from "@services/websocket/useSocketSubscribe";
import { AyolSocketEvents } from "@services/websocket/socket.types";
import { getAllChats } from "@services/ayolApi/methods";
import { useAuth } from "./Auth.context";
import { sleep } from "@utils/sleep";

export interface ChatData extends ChatDto {
  hasMoreMessages: boolean;
}

interface ChatsData {
  status: FetchStatus;
  chats: ChatData[];
  error: Error | AxiosError | null;
}

interface ContextValue extends ChatsData {
  parseRawChat: (rawChat: ChatDto) => ChatData;

  fetchAllChats: (girlName?: string) => Promise<void>;
  getChat: (chatId: string) => ChatData | undefined;
  findChatByGirlId: (girlId: string) => ChatData | undefined;

  addNewChat: (newChat: ChatData) => void;
  updateChat: (updatedChat: Partial<ChatData>) => void;

  removeChat: (chatId: string) => void;
  addNewMessage: (chatId: string, newMssage: ChatMessageDto) => void;
  addPreviousMessages: (chatId: string, messages: ChatMessageDto[]) => void;
  sortChats: (chats: ChatData[]) => ChatData[];
}

const initialChatsData = (): ChatsData => ({
  status: "loading",
  chats: [],
  error: null
});

const ChatsContext = React.createContext(null as any);

export const ChatsProvider = ({ children }: IContext) => {
  const [chatsData, setChatsData] = useState<ChatsData>(initialChatsData());
  const { authStatus } = useAuth();

  useEffect(() => {
    if (authStatus !== "loggedIn") return;

    fetchAllChats();
  }, [authStatus]);

  useSocketSubscribe(AyolSocketEvents.CHAT_STATUS, ({ chat }) => {
    updateChat({ id: chat.id, status: chat.status });
  });

  useSocketSubscribe(AyolSocketEvents.CHAT_MESSAGE_RECEIVED, ({ message }) => {
    addNewMessage(message.chat, message);
  });

  const parseRawChat = (rawChat: ChatDto): ChatData => {
    return {
      ...rawChat,
      hasMoreMessages: true
    };
  };

  const fetchAllChats = async (girlName?: string) => {
    try {
      setChatsData(initialChatsData());

      await sleep(300);

      const res = await getAllChats(girlName);

      return setChatsData((prevState) => ({
        ...prevState,
        chats: res.data.map((chat) => parseRawChat(chat)),
        status: "success"
      }));
    } catch (e: any) {
      setChatsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const getChat = useCallback(
    (chatId: string) => {
      return chatsData?.chats?.find((chat) => chat.id === chatId);
    },
    [chatsData]
  );

  const findChatByGirlId = useCallback(
    (girlId: string) => {
      return chatsData.chats.find((chat) => chat.girl.id === girlId);
    },
    [chatsData]
  );

  const addNewChat = useCallback(
    (newChat: ChatData) => {
      setChatsData((prevState) => {
        const updatedChats = [newChat, ...prevState.chats];
        return { ...prevState, chats: sortChats(updatedChats) };
      });
    },
    [chatsData]
  );

  const updateChat = useCallback(
    (updatedChat: Partial<ChatData>) => {
      setChatsData((prevState) => {
        const updatedChats = prevState.chats.map((chat) => {
          if (chat.id === updatedChat.id) {
            return { ...chat, ...updatedChat };
          }
          return chat;
        });

        const sortedChats = sortChats(updatedChats);

        return {
          ...prevState,
          chats: sortedChats
        };
      });
    },
    [chatsData]
  );

  const removeChat = useCallback(
    (chatId: string) => {
      setChatsData((prevState) => ({
        ...prevState,
        chats: prevState.chats.filter((chat) => chat.id !== chatId)
      }));
    },
    [chatsData]
  );

  const addNewMessage = useCallback(
    (chatId: string, newMessage: ChatMessageDto) => {
      setChatsData((prevState) => {
        const updatedChats = prevState.chats.map((chat) => {
          if (chat.id === chatId) {
            return { ...chat, updatedAt: newMessage.updatedAt, history: [...chat.history, newMessage] };
          }
          return chat;
        });

        return { ...prevState, chats: sortChats(updatedChats) };
      });
    },
    [chatsData]
  );

  const addPreviousMessages = (chatId: string, messages: ChatMessageDto[]) => {
    const foundChat = chatsData.chats.find((chat) => chat.id === chatId);

    if (!foundChat) return;

    setChatsData((prevState) => ({
      ...prevState,
      chats: prevState.chats.map((chat) => {
        if (chat.id === chatId) {
          const uniqueMessages = new Map();

          messages.forEach((message) => uniqueMessages.set(message.id, message));

          chat.history.forEach((message) => {
            if (!uniqueMessages.has(message.id)) {
              uniqueMessages.set(message.id, message);
            }
          });

          return { ...chat, history: Array.from(uniqueMessages.values()) };
        }

        return chat;
      })
    }));
  };

  const sortChats = (chats: ChatData[]) => {
    return chats.sort((a, b) => {
      if (a.favorite && !b.favorite) {
        return -1;
      }
      if (!a.favorite && b.favorite) {
        return 1;
      }

      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return dateB - dateA;
    });
  };

  const contextValue: ContextValue = {
    ...chatsData,
    parseRawChat,
    fetchAllChats,

    getChat,
    findChatByGirlId,

    addNewChat,
    updateChat,
    removeChat,
    addNewMessage,
    addPreviousMessages,
    sortChats
  };

  return <ChatsContext.Provider value={contextValue}>{children}</ChatsContext.Provider>;
};

export const useChats = (): ContextValue => {
  const context = React.useContext(ChatsContext);

  if (context === undefined) {
    throw new Error("useChats must be used within an ChatsProvider");
  }

  return context;
};
