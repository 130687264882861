import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";
import { useAuth } from "@contexts/Auth.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";
import Spinner from "@components/toolkit/Spinner/Spinner";

import "./AuthLoginModal.scss";

const AuthLoginModal = () => {
  const { authStatus, authError, login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation(["modals", "common"]);

  const connecting = authStatus === "logging";

  const handleConnect = async () => {
    if (connecting) return;

    await login();
  };

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(PATHS.main.path);
    }
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <div className="auth-login-modal">
            <h3>{t("modals:authLogin.title")}</h3>

            {authError && <p className="p0 semi-bold danger">{authError.message}</p>}

            <ModalButton onClick={handleConnect} className="mt-4" disabled={connecting}>
              {t("common:login")}
              {connecting && <Spinner className="ml-3" />}
            </ModalButton>

            <ModalButton variant="secondary" onClick={goBack}>
              {t("common:goBack")}
            </ModalButton>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default AuthLoginModal;
