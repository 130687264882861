import { useEffect, useState } from "react";

import { IChildren } from "@src/types/IChildren.types";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";

import AgeVerificationModal from "./AgeVerificationModal/AgeVerificationModal";

export const NSFW_CHECKED_KEY = "NSFW_CHECKED";

const AgeVerificationGuard = ({ children }: IChildren) => {
  const [nsfwChecked, setNsfwChecked] = useState(true);

  useEffect(() => {
    const checked = BrowserStorageManager.readLocalStorage<boolean>(NSFW_CHECKED_KEY);
    setNsfwChecked(!!checked);
  }, []);

  const handleAbort = () => setNsfwChecked(false);
  const handleConfirm = () => setNsfwChecked(true);

  if (!nsfwChecked) return <AgeVerificationModal onAbort={handleAbort} onConfirm={handleConfirm} />;

  return <>{children}</>;
};

export default AgeVerificationGuard;
