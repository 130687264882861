/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

import { IContext } from "@src/types/IContext.types";
import { AppConfig } from "@config/config";
import { ayolApiClient } from "@services/ayolApi/ayolApiClient.instance";
import { ApiHeaders } from "@services/ayolApi/apiHeaders";
import { useAuth } from "@contexts/Auth.context";

interface ContextValue {
  socket: Socket | null;
}

const AyolSocketContext = React.createContext<ContextValue>({
  socket: null
});

export const AyolSocketProvider = ({ children }: IContext) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { authStatus } = useAuth();

  useEffect(() => {
    const initializeSocket = async () => {
      if (authStatus !== "loggedIn") return;

      const { token } = await ayolApiClient.getJWT();

      const newSocket = io(AppConfig.AyolSocketUrl, {
        extraHeaders: {
          [ApiHeaders.AccessToken]: token || ""
        },
        withCredentials: true
      });

      // newSocket.on("connect", () => console.info("Socket connected:", newSocket.id));
      // newSocket.on("reconnect", (attemptNumber: number) => console.info("Socket reconnected:", attemptNumber));
      // newSocket.on("disconnect", (reason: string) => console.info("Socket disconnected:", reason));
      // newSocket.on("error", (msg: string) => console.error("SocketIO: Error", msg));

      setSocket(newSocket);

      return () => {
        newSocket.removeAllListeners();
        newSocket.close();
      };
    };

    initializeSocket();

    return () => {
      if (socket) {
        socket.removeAllListeners();
        socket.close();
      }
    };
  }, [authStatus]);

  const contextValue: ContextValue = {
    socket
  };

  return <AyolSocketContext.Provider value={contextValue}>{children}</AyolSocketContext.Provider>;
};

export const useGirlSocket = (): ContextValue => {
  const context = React.useContext(AyolSocketContext);

  if (context === undefined) {
    throw new Error("useGirlSocket must be used within an AyolSocketProvider");
  }

  return context;
};
