import React, { useEffect, useReducer, useRef } from "react";

import { ReactComponent as ChevronLeft } from "@images/icons/icon-chevron-left.svg";
import { ReactComponent as ChevronRight } from "@images/icons/icon-chevron-right.svg";

import FullScreenSlider from "../FullScreenSlider/FullScreenSlider";

import { initialState, sliderReducer } from "./ImageSlider.reducer";
import "./ImageSlider.scss";

const CHEVRON_SIZE = 48;
const DRAG_THRESHOLD = 100;

interface ImageSliderProps {
  sliderId?: string;
  images: { id: string; imageUrl: string }[];
}

const ImageSlider = ({ sliderId = "", images }: ImageSliderProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(sliderReducer, initialState);

  const goToIndex = (index: number) => dispatch({ type: "SET_CURRENT_INDEX", index });
  const goToNext = () => goToIndex((state.currentIndex + 1) % images.length);
  const goToPrevious = () => goToIndex((state.currentIndex - 1 + images.length) % images.length);

  const openFullScreen = () => dispatch({ type: "SET_FULLSCREEN_OPEN", isOpen: true });
  const closeFullScreen = () => dispatch({ type: "SET_FULLSCREEN_OPEN", isOpen: false });

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
    dispatch({ type: "START_DRAGGING", start: clientX });
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!state.isDragging) return;
    e.preventDefault();

    const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
    const newDragOffset = clientX - state.mouseStart;

    dispatch({ type: "SET_DRAG_OFFSET", offset: newDragOffset });
  };

  const handleDragEnd = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();

    if (Math.abs(state.dragOffset) > 10) {
      if (state.dragOffset > DRAG_THRESHOLD) {
        goToPrevious();
      } else if (state.dragOffset < -DRAG_THRESHOLD) {
        goToNext();
      }
    } else {
      openFullScreen();
    }

    dispatch({ type: "STOP_DRAGGING" });
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.preventDefault();

    if (state.isDragging) {
      dispatch({ type: "STOP_DRAGGING" });
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => handleDragStart(e);
  const handleTouchMove = (e: React.TouchEvent) => handleDragMove(e);
  const handleTouchEnd = (e: React.TouchEvent) => handleDragEnd(e);

  const calculateTransform = () => {
    if (!carouselRef.current) return;

    const offsetWidth = carouselRef.current.offsetWidth;
    const transformValue = state.currentIndex * -100 + (state.dragOffset / offsetWidth) * 100;

    return `translateX(${transformValue}%)`;
  };

  const checkPaginationActive = (index: number) => (index === state.currentIndex ? "active" : "");
  const handleChooseSlide = (index: number) => () => goToIndex(index);

  const adjustPagination = () => {
    const sliderContainer = document.querySelector(".image-slider");

    if (sliderContainer instanceof HTMLElement && images.length > 0) {
      const containerWidth = sliderContainer.offsetWidth;
      const maxPaginationWidth = Math.min(containerWidth * 0.7, containerWidth);

      let itemWidth = 40; // Max item width
      let gap = 16; // Max container gap

      // We calculate the total maximum pagination width
      const maxTotalWidth = images.length * itemWidth + (images.length - 1) * gap;

      // We adjust itemWidth and gap if they exceed the maximum pagination width
      if (maxTotalWidth > maxPaginationWidth) {
        const spaceAvailablePerItem = maxPaginationWidth / images.length;
        gap = Math.max(2, spaceAvailablePerItem - itemWidth);
        itemWidth = Math.max(2, spaceAvailablePerItem - gap);
      }

      const paginationContainer = sliderContainer.querySelector(".image-slider-pagination");
      if (paginationContainer instanceof HTMLElement) {
        paginationContainer.style.gap = `${gap}px`;

        const paginationItems = paginationContainer.querySelectorAll(".pagination-item");

        paginationItems.forEach((item) => {
          if (item instanceof HTMLElement) {
            item.style.width = `${itemWidth}px`;
          }
        });
      }
    }
  };

  useEffect(() => {
    adjustPagination();
    window.addEventListener("resize", adjustPagination);

    return () => {
      window.removeEventListener("resize", adjustPagination);
    };
  }, [images.length]);

  const cursorStyle = state.isDragging ? "grabbing" : "pointer";

  return (
    <>
      <div className="image-slider">
        <div
          ref={carouselRef}
          onMouseDown={handleDragStart}
          onMouseMove={handleDragMove}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{ cursor: cursorStyle }}
          className="image-slider-main"
        >
          <div className="image-slider-images" style={{ transform: calculateTransform() }}>
            {images.map(({ id, imageUrl }, index) => (
              <img key={`${id || Math.random()}-slide-${index}`} src={imageUrl} alt={`Slide ${index}`} />
            ))}
          </div>
        </div>

        <button className="image-slider-button left-button" onClick={goToPrevious}>
          <ChevronLeft width={CHEVRON_SIZE} height={CHEVRON_SIZE} />
        </button>

        <button className="image-slider-button right-button" onClick={goToNext}>
          <ChevronRight width={CHEVRON_SIZE} height={CHEVRON_SIZE} />
        </button>

        <div className="image-slider-pagination">
          {images.map((_, index) => (
            <span
              key={`${sliderId}-pagination-btn-${index}`}
              className={`pagination-item ${checkPaginationActive(index)}`}
              onClick={handleChooseSlide(index)}
            />
          ))}
        </div>
      </div>

      {state.fullScreenOpen && (
        <FullScreenSlider initialIndex={state.currentIndex} images={images} onClose={closeFullScreen} />
      )}
    </>
  );
};

export default ImageSlider;
