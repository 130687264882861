import { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";
import { useWallet } from "@services/metamask/WalletProvider.context";
import { useAuth } from "@contexts/Auth.context";
import useBoolean from "@hooks/useBoolean";

import SwitchNetwork from "./SwitchNetwork/SwitchNetwork";

const SwitchNetworkGuard = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const { authStatus } = useAuth();

  const { metamaskStatus, isOnCorrectChain } = useWallet();

  const showSwitchNetworkModal = metamaskStatus === "connected" && !isOnCorrectChain && authStatus !== "logging";

  useEffect(() => {
    if (showSwitchNetworkModal) openModal();
    else closeModal();
  }, [metamaskStatus, isOnCorrectChain]);

  return (
    <>
      {children}
      {isModalOpen && <SwitchNetwork onClose={closeModal} />}
    </>
  );
};

export default SwitchNetworkGuard;
