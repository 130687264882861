import girlsPageEn from "@locales/en/girls-page.json";
import chatsEn from "@locales/en/chats.json";
import layoutEn from "@locales/en/layout.json";
import modalsEn from "@locales/en/modals.json";
import apiResponseEn from "@locales/en/api-response.json";
import commonEn from "@locales/en/common.json";

export const resources = {
  en: {
    girlsPage: girlsPageEn,
    chats: chatsEn,
    layout: layoutEn,
    modals: modalsEn,
    apiResponse: apiResponseEn,
    common: commonEn
  }
};
