import React from "react";
import { useTranslation } from "react-i18next";

import "./Hero.scss";

const Hero = () => {
  const { t } = useTranslation("girlsPage");

  return (
    <section id="main-page-hero" className="hero">
      <video muted loop autoPlay playsInline className="hero-video">
        <source src="/videos/hero-video.webm" type="video/webm" />
        <source src="/videos/hero-video.mp4" type="video/mp4" />
      </video>

      <div className="hero-content">
        <div className="subtitle-box">
          <span className="text-uppercase">{t("hero.subtitle")}</span>
        </div>
        <h1 className="hero-title mt-5">{t("hero.title")}</h1>
      </div>
    </section>
  );
};

export default Hero;
