import React from "react";
import { usePopper } from "react-popper";

import { IChildren } from "@src/types/IChildren.types";
import Stack from "@components/arrangement/Stack/Stack";

import { useDropdownDataContext } from "../Dropdown";

const DropdownMenu = ({ children }: IChildren) => {
  const { isMenuOpen, dropdownRef, popperRef, placement, setPopperRef } = useDropdownDataContext();

  const { styles, attributes } = usePopper(dropdownRef, popperRef, {
    placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 5]
        }
      }
    ]
  });

  return (
    <>
      {isMenuOpen && (
        <div ref={setPopperRef} className="dropdown-menu" style={{ ...styles.popper }} {...attributes.popper}>
          <Stack rowGap={8}>{children}</Stack>
        </div>
      )}
    </>
  );
};

export default DropdownMenu;
