import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PATHS } from "@router/paths";
import { fetchSetFavoriteChat } from "@services/ayolApi/methods";
import { useAppModals } from "@contexts/AppModals.context";
import { useChats } from "@contexts/Chats.context";
import useRequest from "@hooks/useRequest";

import { ReactComponent as ChatCardOptionsIcon } from "@images/icons/icon-chat-card-options.svg";

import Dropdown from "@components/common/Dropdown/Dropdown";
import Group from "@components/arrangement/Group/Group";
import FavChatBadge from "../FavChatBadge/FavChatBadge";

import "./ChatNavCard.scss";

interface ChatNavCardProps {
  id: string;
  name: string;
  imageUrl: string;
  lastMessage?: string;
  favorite?: boolean;
  className?: string;
}

const ChatNavCard = ({ id, name, imageUrl, lastMessage = "", favorite = false, className }: ChatNavCardProps) => {
  const { chatId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const { updateChat } = useChats();
  const { openModal } = useAppModals();

  const isChatActive = useMemo(() => chatId === id, [chatId, id]);

  const navigateToChat = (chatId: string) => () => navigate(PATHS.chat.getPath(chatId));

  const { request: requestSetFavorite, loading } = useRequest({
    requestFn: (chatId: string, favorite: boolean) => {
      return fetchSetFavoriteChat(chatId, favorite);
    },
    onSuccess: (res) => {
      updateChat({ id: res.data.id, favorite: res.data.favorite });
    },
    onFail: (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  const handleSetFavorite = async () => {
    if (loading) return;
    requestSetFavorite(id, !favorite);
  };

  const handleRemoveChat = () => {
    openModal({ type: "removeChat", data: { chatId: id } });
  };

  const classes = classNames("chat-nav-card", { "chat-nav-card--active": isChatActive }, className);

  return (
    <div role="menu" onClick={navigateToChat(id)} className={classes}>
      <div className="chat-nav-card-content">
        <img src={imageUrl} alt={`${name} profile image`} width={48} height={48} className="chatter-image" />

        <div className="chatter-info">
          <p className="p0 nick">{name}</p>
          <p className="p1 last-message">{lastMessage}</p>
        </div>
      </div>

      <div className="chat-nav-card-actions">
        <Dropdown>
          <Dropdown.Target>
            <ChatCardOptionsIcon />
          </Dropdown.Target>

          <Dropdown.Menu>
            <Dropdown.MenuItem onClick={handleRemoveChat}>
              <Group colGap={4} alignItems="center" justifyContent="center">
                <img src="/images/icons/icon-trash.svg" alt="Remove chat" width={24} height={24} />
                <p className="p1 semi-bold text-uppercase line-h-normal">{t("removeChat")}</p>
              </Group>
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>

        <FavChatBadge favorite={favorite} onSetFavorite={handleSetFavorite} className="action-btn" />
      </div>
    </div>
  );
};

export default ChatNavCard;
