import React from "react";
import { useTranslation } from "react-i18next";

import { useAppModals } from "@contexts/AppModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";

import "./FailedCreateChat.scss";

const FailedCreateChat = () => {
  const { closeModal } = useAppModals();

  const { t } = useTranslation(["modals", "apiResponse", "common"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <div className="failed-create-chat">
            <img src="/images/icons/icon-error.svg" alt="" />
            <h3 className="mt-7">{t("apiResponse:error.baseSmall")}</h3>
            <p className="mt-5">{t("apiResponse:error.createChat")}</p>

            <ModalButton variant="secondary" onClick={closeModal} className="mt-6">
              {t("common:close")}
            </ModalButton>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default FailedCreateChat;
